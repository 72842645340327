import moment from 'moment';

import {
    formatThaiDateUi,
    getCreateDateTime
} from '../../utils/DateUtils';

export const whDocMasterModel = () => {
    return {
        whDocId: null,
        whDocNo: null,
        whDocType: null,
        whDocCustId: null,
        whDocCustCode: null,
        whDocCustName: null,
        whDocDate: formatThaiDateUi(moment()),
        whDocTransDate: formatThaiDateUi(moment()),
        whDocCreatedDate: getCreateDateTime(),
        whDocCreatedBy: window.localStorage.getItem('name'),
        whDocStatus: null,
        whDocSeq: null,
        whDocRemark: null,
        whDocCompId: window.localStorage.getItem('company'),
        whDocShowFront: null,
        refDocId: null,
        refDoc: null,
        refDocDate: null,
        fWhId: null,
        fZoneId: null,
        fLtId: null,
        tWhId: null,
        tZoneId: null,
        tLtId: null,

        // ใช้สำหรับแสดงเฉยๆ
        arAdd1: null,
        arAdd2: null,
        arAdd3: null,
        arProvince: null,
        arZipcode: null,
        arTaxNo: null,
    };
};