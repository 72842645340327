import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Pages
import Login from "./views/pages/Login";
import Home from "./views/pages/Home";

// จัดซื้อสินค้า
import Purchase from "./views/pages/Purchase";
import DepositDocument from "./views/pages/Purchase/DepositDoc";
import PurchaseRequest from "./views/pages/Purchase/PurchaseRequest";
import PurchaseOrder from "./views/pages/Purchase/PurchaseOrder";
import ProductReceipt from "./views/pages/Purchase/ProductReceipt";
import PaymentVoucher from "./views/pages/Purchase/PaymentVoucher";

// คลังสินค้า
import Warehouse from "./views/pages/Warehouse";
import WarehouseStock from "./views/pages/Warehouse/WarehouseStock";
import TreasuryDocuments from "./views/pages/Warehouse/TreasuryDocuments";
import BillOfLading from "./views/pages/Warehouse/BillOfLading";

// ขายสินค้า
import SellProducts from "./views/pages/SellProducts";
import Quotation from "./views/pages/SellProducts/Quotation";
import OrderProduct from "./views/pages/SellProducts/OrderProduct";
import BillOfSale from "./views/pages/SellProducts/BillOfSale";
import Taxes from "./views/pages/SellProducts/Taxes";
import PickingList from "./views/pages/SellProducts/PickingList";

// InitialData
import InitialData from "./views/pages/InitialData";
import TransportInformation from "./views/pages/InitialData/TransportInformation";
import Creditor from "./views/pages/InitialData/Creditor";
import Debtor from "./views/pages/InitialData/Debtor";
import Company from "./views/pages/InitialData/Company";
import DepartMent from "./views/pages/InitialData/DepartMent";
import Employee from "./views/pages/InitialData/Employee";
import TbSetItem from "./views/pages/InitialData/TbSetItem";
import TbSetItemBrand from "./views/pages/InitialData/TbSetItemBrand";
import TbSetItemCat from "./views/pages/InitialData/TbSetItemCat";
import TbSetItemColour from "./views/pages/InitialData/TbSetItemColour";
import TbSetItemGroup from "./views/pages/InitialData/TbSetItemGroup";
import TbSetItemSize from "./views/pages/InitialData/TbSetItemSize";
import TbSetItemPosition from "./views/pages/InitialData/TbSetItemPosition";
import TbSetItemTransType from "./views/pages/InitialData/TbSetItemTransType";
import TbSetItemUnit from "./views/pages/InitialData/TbSetItemUnit";

// 404
import NotFoundPage from "./views/pages/404";
const root = ReactDOM.createRoot(document.getElementById("root"));
const isLoggedIn = localStorage.getItem('token');

root.render(
  <BrowserRouter>
    <Routes>

      {/* จัดซื้อสินค้า */}
      <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
      <Route path="/purchase" element={isLoggedIn ? <Purchase /> : <Navigate to="/login" />} />
      <Route path="/deposit-document" element={isLoggedIn ? <DepositDocument /> : <Navigate to="/login" />} />
      <Route path="/purchase-request" element={isLoggedIn ? <PurchaseRequest /> : <Navigate to="/login" />} />
      <Route path="/purchase-order" element={isLoggedIn ? <PurchaseOrder /> : <Navigate to="/login" />} />
      <Route path="/product-receipt" element={isLoggedIn ? <ProductReceipt /> : <Navigate to="/login" />} />
      <Route path="/payment-voucher" element={isLoggedIn ? <PaymentVoucher /> : <Navigate to="/login" />} />

      {/* คลังสินค้า */}
      <Route path="/warehouse" element={isLoggedIn ? <Warehouse /> : <Navigate to="/login" />} />
      <Route path="/warehouse-stock" element={isLoggedIn ? <WarehouseStock /> : <Navigate to="/login" />} />
      <Route path="/treasury-documents" element={isLoggedIn ? <TreasuryDocuments /> : <Navigate to="/login" />} />
      <Route path="/bill-of-lading" element={isLoggedIn ? <BillOfLading /> : <Navigate to="/login" />} />

      {/* ขายสินค้า */}
      <Route path="/sellProducts" element={isLoggedIn ? <SellProducts /> : <Navigate to="/login" />} />
      <Route path="/quotation" element={isLoggedIn ? <Quotation /> : <Navigate to="/login" />} />
      <Route path="/order-product" element={isLoggedIn ? <OrderProduct /> : <Navigate to="/login" />} />
      <Route path="/billofsale" element={isLoggedIn ? <BillOfSale /> : <Navigate to="/login" />} />
      <Route path="/taxes" element={isLoggedIn ? <Taxes /> : <Navigate to="/login" />} />
      <Route path="/picking-list" element={isLoggedIn ? <PickingList /> : <Navigate to="/login" />} />

      {/* ข้อมูลตั้งต้น */}
      <Route path="/initialdata" element={isLoggedIn ? <InitialData /> : <Navigate to="/login" />} />
      <Route path="/transport-information" element={isLoggedIn ? <TransportInformation /> : <Navigate to="/login" />} />
      <Route path="/creditor" element={isLoggedIn ? <Creditor /> : <Navigate to="/login" />} />
      <Route path="/debtor" element={isLoggedIn ? <Debtor /> : <Navigate to="/login" />} />
      <Route path="/company" element={isLoggedIn ? <Company /> : <Navigate to="/login" />} />
      <Route path="/department" element={isLoggedIn ? <DepartMent /> : <Navigate to="/login" />} />
      <Route path="/employee" element={isLoggedIn ? <Employee /> : <Navigate to="/login" />} />
      <Route path="/tbsetitem" element={isLoggedIn ? <TbSetItem /> : <Navigate to="/login" />} />
      <Route path="/tbsetitembrand" element={isLoggedIn ? <TbSetItemBrand /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemcat" element={isLoggedIn ? <TbSetItemCat /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemcolour" element={isLoggedIn ? <TbSetItemColour /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemgroup" element={isLoggedIn ? <TbSetItemGroup /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemsize" element={isLoggedIn ? <TbSetItemSize /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemposition" element={isLoggedIn ? <TbSetItemPosition /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemtranstype" element={isLoggedIn ? <TbSetItemTransType /> : <Navigate to="/login" />} />
      <Route path="/tbsetitemunit" element={isLoggedIn ? <TbSetItemUnit /> : <Navigate to="/login" />} />

      {/* Login */}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />

    </Routes>
  </BrowserRouter>
);