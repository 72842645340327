import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// Components
import Breadcrumbs from '../../Breadcrumbs';
import FormAction from '../../Actions/FormAction';
import { deleteDetail, getAlert } from '../../../../utils/SamuiUtils';

const Form = ({ callInitialize, mode, name, formData, setFormData, selectedData }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);

  useEffect(() => {
    if (mode === 'I') {
      const initialData = {
        ar_id: "", // Int
        comp_id: window.localStorage.getItem('company'), // Int
        ar_code: "",
        ar_name: "",
        ar_type: "", // Int
        ar_shortname: "",
        ar_taxno: "",
        ar_add1: "",
        ar_add2: "",
        ar_add3: "",
        ar_province: "",
        ar_zipcode: "",
        ar_contact1: "",
        ar_contact2: "",
        ar_tel: "",
        ar_tel2: "",
        ar_group: "",
        ar_refsalecode: "",
        ar_area: "",
        ar_status: "Y",
        ar_credit: 0, // Int
        ar_vat: 0,    // Int
        ar_discount: "",
        ar_branch: "",
        ar_bt_name: "",
        ar_add_lat: "",
        ar_add_lan: "",
        ar_refsalename: "",
        ar_credit_term: "",
        ar_credit_limit: "",
      }
      // กำหนดค่าเริ่มต้นให้กับ form data
      setFormData(initialData);
      // กำหนดค่าเริ่มต้นให้กับ initial form data
      setInitialFormData(initialData);
    } else if (mode === 'U') {
      const initialData = {
        ar_id: selectedData.AR_Id, // Int
        comp_id: window.localStorage.getItem('company'), // Int
        ar_code: selectedData.AR_Code,
        ar_name: selectedData.AR_Name,
        ar_type: selectedData.AR_Type, // Int
        ar_shortname: selectedData.AR_ShortName,
        ar_taxno: selectedData.AR_TaxNo,
        ar_add1: selectedData.AR_Add1,
        ar_add2: selectedData.AR_Add2,
        ar_add3: selectedData.AR_Add3,
        ar_province: selectedData.AR_Province,
        ar_zipcode: selectedData.AR_Zipcode,
        ar_contact1: selectedData.AR_Contact1,
        ar_contact2: selectedData.AR_Contact2,
        ar_tel: selectedData.AR_Tel,
        ar_tel2: selectedData.AR_Tel2,
        ar_group: selectedData.AR_Group,
        ar_refsalecode: selectedData.AR_RefSaleCode,
        ar_area: selectedData.AR_Area,
        ar_status: selectedData.AR_Status,
        ar_credit: parseInt(selectedData.AR_Credit, 10), // Int
        ar_vat: parseInt(selectedData.AR_Vat, 10),    // Int
        ar_discount: selectedData.AR_Discount,
        ar_branch: selectedData.AR_Branch,
        ar_bt_name: selectedData.AR_BT_Name,
        ar_add_lat: selectedData.AR_ADDLAT,
        ar_add_lan: selectedData.AR_ADDLAN,
        ar_refsalename: selectedData.AR_RefSaleName,
        ar_credit_term: selectedData.AR_Credit_Term,
        ar_credit_limit: selectedData.AR_Credit_Limit,
      }
      // อัปเดตค่าให้กับ form data
      setFormData(initialData);
      // อัปเดตค่าให้กับ initial form data
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedData]);


  // ฟังก์ชันที่ใช้ในการเปลี่ยนค่าของข้อมูลในฟอร์ม
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInsertAR = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-tb-set-ar`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกสำเร็จ");
      // callInitialize();
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };
  const handleUpdateAR = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-tb-set-ar`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      // callInitialize();
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDetail('Tb_Set_AR', `WHERE AR_Id = ${selectedData.AR_Id}`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (
        !formData.ar_code ||
        !formData.ar_name ||
        !formData.ar_type ||
        !formData.ar_shortname ||
        !formData.ar_taxno ||
        !formData.ar_add1 ||
        !formData.ar_add2 ||
        !formData.ar_add3 ||
        !formData.ar_province ||
        !formData.ar_zipcode ||
        !formData.ar_contact1 ||
        !formData.ar_contact2 ||
        !formData.ar_tel ||
        !formData.ar_tel2 ||
        !formData.ar_group ||
        !formData.ar_refsalecode ||
        !formData.ar_area ||
        !formData.ar_status ||
        !formData.ar_credit ||
        !formData.ar_vat ||
        !formData.ar_discount ||
        !formData.ar_branch ||
        !formData.ar_bt_name ||
        !formData.ar_addlat ||
        !formData.ar_addlan ||
        !formData.ar_refsalename ||
        !formData.ar_credit_term ||
        !formData.ar_credit_limit
      ) {
        getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วน");
        return;
      }
      // ตรวจสอบว่าข้อมูลที่แก้ไขว่าซ้ำกับข้อมูลเดิมหรือไม่
      if (formData.ar_code === initialFormData.ar_code &&
        formData.ar_name === initialFormData.ar_name &&
        formData.ar_type === initialFormData.ar_type &&
        formData.ar_shortname === initialFormData.ar_shortname &&
        formData.ar_taxno === initialFormData.ar_taxno &&
        formData.ar_add1 === initialFormData.ar_add1 &&
        formData.ar_add2 === initialFormData.ar_add2 &&
        formData.ar_add3 === initialFormData.ar_add3 &&
        formData.ar_province === initialFormData.ar_province &&
        formData.ar_zipcode === initialFormData.ar_zipcode &&
        formData.ar_contact === initialFormData.ar_contact &&
        formData.ar_contact2 === initialFormData.ar_contact2 &&
        formData.ar_tel === initialFormData.ar_tel &&
        formData.ar_tel2 === initialFormData.ar_tel2 &&
        formData.ar_group === initialFormData.ar_group &&
        formData.ar_refsalecode === initialFormData.ar_refsalecode &&
        formData.ar_area === initialFormData.ar_area &&
        formData.ar_status === initialFormData.ar_status &&
        formData.ar_credit === initialFormData.ar_credit &&
        formData.ar_vat === initialFormData.ar_vat &&
        formData.ar_discount === initialFormData.ar_discount &&
        formData.ar_branch === initialFormData.ar_branch &&
        formData.ar_bt_name === initialFormData.ar_bt_name &&
        formData.ar_addlat === initialFormData.ar_addlat &&
        formData.ar_addlan === initialFormData.ar_addlan &&
        formData.ar_refsalename === initialFormData.ar_refsalename &&
        formData.ar_credit_term === initialFormData.ar_credit_term &&
        formData.ar_credit_limit === initialFormData.ar_credit_limit
      ) {
        getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
        return;
      }
      if (mode === 'I') {
        await handleInsertAR();
      } else if (mode === 'U') {
        await handleUpdateAR();
      }
      await callInitialize(); // หลังจาก handleInsertAP, handleUpdateAP ทำงานเสร็จ callInitialize จะทำงานต่อ
      setFormData({
        ar_id: "", // Int
        comp_id: window.localStorage.getItem('company'), // Int
        ar_code: "",
        ar_name: "",
        ar_type: "", // Int
        ar_shortname: "",
        ar_taxno: "",
        ar_add1: "",
        ar_add2: "",
        ar_add3: "",
        ar_province: "",
        ar_zipcode: "",
        ar_contact: "",
        ar_contact2: "",
        ar_tel: "",
        ar_tel2: "",
        ar_group: "",
        ar_refsalecode: "",
        ar_area: "",
        ar_status: "Y",
        ar_credit: 0, // Int
        ar_vat: 0,    // Int
        ar_discount: "",
        ar_branch: "",
        ar_bt_name: "",
        ar_add_lat: "",
        ar_add_lan: "",
        ar_refsalename: "",
        ar_credit_term: "",
        ar_credit_limit: "",
      })
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  }

  return (
    <form>
      <Breadcrumbs
        isShowStatus={mode === 'U'}
        items={[
          { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
          { name: name, url: '/debtor' },
          { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' },
        ]}
      />
      <div className="row mt-1">
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>รหัสลูกหนี้</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_code"
              value={formData.ar_code || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ชื่อลูกหนี้</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_name"
              value={formData.ar_name || ""}
              onChange={handleChange}

            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_type</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="ar_type"
              value={formData.ar_type || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ชื่อย่อ</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_shortname"
              value={formData.ar_shortname || ""}
              onChange={handleChange}

            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_taxNo</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_taxno"
              value={formData.ar_taxno || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ที่อยู่ 1</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_add1"
              value={formData.ar_add1 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ที่อยู่ 2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_add2"
              value={formData.ar_add2 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ที่อยู่ 3</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_add3"
              value={formData.ar_add3 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>จังหวัด</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_province"
              value={formData.ar_province || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>รหัสไปรษณีย์</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_zipcode"
              value={formData.ar_zipcode || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ติดต่อ</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_contact1"
              value={formData.ar_contact1 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ติดต่อ 2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_contact2"
              value={formData.ar_contact2 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>เบอร์โทร</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_tel"
              value={formData.ar_tel || ""}
              onChange={handleChange}
              maxLength="10"
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>เบอร์โทร 2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_tel2"
              value={formData.ar_tel2 || ""}
              onChange={handleChange}
              maxLength="10"
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>กลุ่ม</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_group"
              value={formData.ar_group || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>รหัสอ้างอิงการขาย</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_refsalecode"
              value={formData.ar_refsalecode || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>พื้นที่</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_area"
              value={formData.ar_area || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>สถานะ</label>
            <select
              className="form-select"
              name="ar_status"
              value={formData.ar_status || "Y"} // Set default value to "Y"
              onChange={handleChange}
              style={{ color: 'black' }}
            >
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>เครดิต</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="ar_credit"
              value={formData.ar_credit || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ภาษีมูลค่าเพิ่ม</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="ar_vat"
              value={formData.ar_vat || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>การลดราคา</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_discount"
              value={formData.ar_discount || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>สาขา</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_branch"
              value={formData.ar_branch || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_BT_Name</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_bt_name"
              value={formData.ar_bt_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_Addlat</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_addlat"
              value={formData.ar_addlat || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_Addlan</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_addlan"
              value={formData.ar_addlan || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_RefSaleName</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ar_refsalename"
              value={formData.ar_refsalename || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_Credit_Term</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="ar_credit_term"
              value={formData.ar_credit_term || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AR_Credit_Limit</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="ar_credit_limit"
              value={formData.ar_credit_limit || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <FormAction
          onSubmit={handleSubmit}
          onUpdate={handleSubmit}
          onDelete={handleDelete}
          mode={mode}
        />
      </div>
    </form>
  );
};

export default Form;