import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// Components
import Breadcrumbs from '../../Breadcrumbs';
import FormAction from '../../Actions/FormAction';
import { deleteDetail, getAlert } from '../../../../utils/SamuiUtils';

const Form = ({ callInitialize, mode, name, formData, setFormData, selectedData }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);

  useEffect(() => {
    if (mode === 'I') {
      const initialData = {
        item_id: "", // Int
        comp_id: parseInt(window.localStorage.getItem('company'), 10),
        item_barcode: "",
        item_code: "",
        item_name: "",
        item_name_en: "",
        item_short_name: "",
        item_unit_in: "",
        item_unit_st: "",
        item_unit_out: "",
        item_cost: "",
        item_price: "",
        item_pricea: "",
        item_priceb: "",
        item_brand: "",
        item_series: "",
        item_group: "",
        item_cat: "",
        item_colour: "",
        item_size: "",
        item_status: "",
        item_popoint: "",
        item_maxonhand: "",
        ap_code: "",
        item_gold: "",
        item_onhand: "",
        item_wh: "",
      }
      setFormData(initialData);
      setInitialFormData(initialData);
    } else if (mode === 'U') {
      const initialData = {
        item_id: parseInt(selectedData.Item_Id, 10),
        comp_id: parseInt(selectedData.Comp_Id, 10),
        item_barcode: selectedData.Item_Barcode,
        item_code: selectedData.Item_Code,
        item_name: selectedData.Item_Name,
        item_name_en: selectedData.Item_Name_EN,
        item_short_name: selectedData.Item_Short_Name,
        item_unit_in: selectedData.Item_Unit_IN,
        item_unit_st: selectedData.Item_Unit_ST,
        item_unit_out: selectedData.Item_Unit_OUT,
        item_cost: parseInt(selectedData.Item_Cost, 10),
        item_price: parseInt(selectedData.Item_Price, 10),
        item_pricea: (selectedData.Item_PriceA),
        item_priceb: parseInt(selectedData.Item_PriceB, 10),
        item_brand: selectedData.Item_Brand,
        item_series: selectedData.Item_Series,
        item_group: selectedData.Item_Group,
        item_cat: selectedData.Item_Cat,
        item_colour: selectedData.Item_Colour,
        item_size: selectedData.Item_Size,
        item_status: selectedData.Item_Status,
        item_popoint: parseInt(selectedData.Item_PoPoint, 10),
        item_maxonhand: parseInt(selectedData.Item_MaxOnhand, 10),
        ap_code: selectedData.AP_Code,
        item_gold: parseInt(selectedData.Item_Gold),
        item_onhand: parseInt(selectedData.Item_Onhand, 10),
        item_wh: selectedData.Item_WH
      }
      setFormData(initialData);
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleInsertTbsetItem = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-tb-set-item`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleUpdateTbsetItem = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-tb-set-item`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDetail('Tb_Set_Item', `WHERE Item_Id = ${selectedData.Item_Id}`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    try {
      // if (!formData.item_barcode || !formData.item_code || !formData.item_name || !formData.item_name_en || !formData.item_short_name ||
      //   !formData.item_unit_in || !formData.item_unit_st || !formData.item_unit_out || !formData.item_cost || !formData.item_price ||
      //   !formData.item_pricea || !formData.item_priceb || !formData.item_brand || !formData.item_series || !formData.item_group ||
      //   !formData.item_cat || !formData.item_color || !formData.item_size || !formData.item_status || !formData.item_popoint ||
      //   !formData.item_maxonhand || !formData.ap_code || !formData.item_gold || !formData.item_onhand || !formData.item_wh) {
      //   getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วน");
      //   return;
      // }
      if (mode === 'I') {
        await handleInsertTbsetItem();
      } else if (mode === 'U') {
        await handleUpdateTbsetItem();
      }
      await callInitialize();
      setFormData({
        item_id: "", // Int
        comp_id: parseInt(window.localStorage.getItem('company'), 10),
        item_barcode: "",
        item_code: "",
        item_name: "",
        item_name_en: "",
        item_short_name: "",
        item_unit_in: "",
        item_unit_st: "",
        item_unit_out: "",
        item_cost: "",
        item_price: "",
        item_pricea: "",
        item_priceb: "",
        item_brand: "",
        item_series: "",
        item_group: "",
        item_cat: "",
        item_color: "",
        item_size: "",
        item_status: "",
        item_popoint: "",
        item_maxonhand: "",
        ap_code: "",
        item_gold: "",
        item_onhand: "",
        item_wh: "",
      })
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  }

  return (
    <form>
      <Breadcrumbs
        isShowStatus={mode === 'U'}
        items={[
          { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
          { name: name, url: '/tbsetitem' },
          { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' },
        ]}
      />
      <div className="row mt-1">
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Barcode</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_barcode"
              value={formData.item_barcode || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Code</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_code"
              value={formData.item_code || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Name</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_name"
              value={formData.item_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Name_EN</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_name_en"
              value={formData.item_name_en || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Short_Name</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_short_name"
              value={formData.item_short_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Unit_IN</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_unit_in"
              value={formData.item_unit_in || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Unit_ST</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_unit_st"
              value={formData.item_unit_st || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Unit_OUT</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_unit_out"
              value={formData.item_unit_out || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Cost</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_cost"
              value={formData.item_cost || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Price</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_price"
              value={formData.item_price || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_PriceA</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_pricea"
              value={formData.item_pricea || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_PriceB</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_priceb"
              value={formData.item_priceb || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Brand</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_brand"
              value={formData.item_brand || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Series</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_series"
              value={formData.item_series || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Group</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_group"
              value={formData.item_group || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Cat</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_cat"
              value={formData.item_cat || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Colour</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_colour"
              value={formData.item_colour || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Size</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_size"
              value={formData.item_size || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>สถานะ</label>
            <select
              className="form-select"
              name="item_status"
              value={formData.item_status || "Y"} // Set default value to "Y"
              onChange={handleChange}
              style={{ color: 'black' }}
            >
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_PoPoint</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_popoint"
              value={formData.item_popoint || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_MaxOnhand</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_maxonhand"
              value={formData.item_maxonhand || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>AP_Code</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="ap_code"
              value={formData.ap_code || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Gold</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_gold"
              value={formData.item_gold || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_Onhand</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="item_onhand"
              value={formData.item_onhand || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Item_WH</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="item_wh"
              value={formData.item_wh || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <FormAction
          onSubmit={handleSubmit}
          onUpdate={handleSubmit}
          onDelete={handleDelete}
          mode={mode}
        />
      </div>
    </form>
  );
};

export default Form;