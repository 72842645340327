import React from 'react';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

function InitialData() {
  const renderCard = (category, icon, newItems, additionalInfo, path) => (
    <div className="col-sm-6 col-md-2" style={{ cursor: 'pointer' }} onClick={() => window.location.replace(path)}>
      <div className="card card-stats card-round">
        <div className="card-body d-flex flex-column align-items-center">
          <div className="col-icon mb-3">
            <div className="icon-big text-center icon-secondary bubble-shadow-small" style={{ backgroundColor: 'orange' }}>
              <i className={icon} />
            </div>
          </div>
          <div className="col col-stats text-center">
            <div className="numbers">
              <p className="card-category">{category}</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li style={{ color: 'orange' }}>{newItems} รายการใหม่</li>
              </ul>
            </div>
          </div>
          <div className="col col-stats text-center">
            <div className="row">
              {additionalInfo.map((item, index) => (
                <div key={index} className="col-4" style={{ color: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <i className="far fa-clock" aria-hidden="true" style={{ fontSize: '15px', marginRight: '5px' }}></i>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="InitialData">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <div className="page-header d-flex justify-content-between align-items-center">
                <Breadcrumbs page={"ข้อมูลไอเท็ม"} items={[
                  { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
                ]} />
              </div>
              <div className="row">
                {renderCard('ข้อมูลรถขนส่ง', 'fas fa-truck', 1, [5, 0, 0], '/transport-information')}
                {renderCard('ข้อมูลเจ้าหนี้', 'fas fa-hand-holding-usd', 1, [5, 0, 0], '/creditor')}
                {renderCard('ข้อมูลลูกหนี้', 'fas fa-hand-holding-heart', 1, [5, 0, 0], '/debtor')}
                {renderCard('ข้อมูลบริษัท', 'fas fa-building', 1, [5, 0, 0], '/company')}
                {renderCard('ข้อมูลแผนก', 'fas fa-sitemap', 1, [5, 0, 0], '/department')}
                {renderCard('ข้อมูลพนักงาน', 'fas fa-id-badge', 1, [5, 0, 0], '/employee')}
                {renderCard('ข้อมูลไอเท็ม', 'fas fa-box', 1, [5, 0, 0], '/tbsetitem')}
                {renderCard('ข้อมูลแบรนด์', 'fas fa-tags', 1, [5, 0, 0], '/tbsetitembrand')}
                {renderCard('ข้อมูลหมวดหมู่', 'fas fa-cogs', 1, [5, 0, 0], '/tbsetitemcat')}
                {renderCard('ข้อมูลสี', 'fas fa-palette', 1, [5, 0, 0], '/tbsetitemcolour')}
                {renderCard('ข้อมูลกรุ๊ปสินค้า', 'fas fa-object-group', 1, [5, 0, 0], '/tbsetitemgroup')}
                {renderCard('ข้อมูลตำแหน่งสินค้า', 'fas fa-map-marker-alt', 1, [5, 0, 0], '/tbsetitemposition')}
                {renderCard('ข้อมูลทรานส์ไทป์', 'fas fa-exchange-alt', 1, [5, 0, 0], '/tbsetitemtranstype')}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default InitialData;