import React, { useState, useEffect } from 'react';

// Utils
import { getAlert } from '../../../utils/SamuiUtils';

const ItemOrderModal = ({
    mode,
    showItemModal,
    handleItemClose,
    itemDataList,
    onRowSelectItem,
    formatThaiDateUi,
    formDetailList,
    odDataList,
    onRowSelectOd
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItemDataList, setFilteredItemDataList] = useState(itemDataList);
    const [filteredOdDataList, setFilteredOdDataList] = useState(odDataList);
    const [selectedType, setSelectedType] = useState('OD');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedStockItems, setSelectedStockItems] = useState([]);

    useEffect(() => {
        setFilteredItemDataList(
            itemDataList.filter(item =>
                item.Item_Code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.Item_Name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, itemDataList]);

    useEffect(() => {
        setFilteredOdDataList(odDataList);
    }, [odDataList]);

    useEffect(() => {
        if (showItemModal) {
            setSearchTerm('');
            if (mode === 'U') {
                autoSelectItems();
            }
        }
    }, [showItemModal, mode]);

    const autoSelectItems = () => {
        const refDocNoMap = formDetailList.reduce((acc, detail) => {
            const refDocNo = detail.refDocNo;
            if (!acc[refDocNo]) {
                acc[refDocNo] = [];
            }
            acc[refDocNo].push(detail);
            return acc;
        }, {});

        const selectedOdNos = new Set();
        Object.keys(refDocNoMap).forEach(refDocNo => {
            filteredOdDataList.forEach(od => {
                if (od.WHDoc_No.trim() === refDocNo) {
                    selectedOdNos.add(od);
                }
            });
        });

        setSelectedItems(Array.from(selectedOdNos));
    };

    const handleCheckboxChange = (od) => {
        if (selectedItems.some(item => item.WHDoc_No === od.WHDoc_No)) {
            setSelectedItems(selectedItems.filter(item => item.WHDoc_No !== od.WHDoc_No));
        } else {
            setSelectedItems([...selectedItems, od]);
        }
    };

    const handleStockItemCheckboxChange = (item) => {
        if (selectedStockItems.some(selectedItem => selectedItem.Item_Id === item.Item_Id)) {
            setSelectedStockItems(selectedStockItems.filter(selectedItem => selectedItem.Item_Id !== item.Item_Id));
        } else {
            setSelectedStockItems([...selectedStockItems, item]);
        }
    };

    const handleConfirmSelection = () => {
        if (selectedType === 'OD' && selectedItems.length === 0) {
            getAlert('FAILED', 'กรุณาเลือกใบออเดอร์สินค้า');
            return; // หยุดการทำงานของฟังก์ชันถ้าไม่มีการเลือก
        }

        if (selectedType === 'ST' && selectedStockItems.length === 0) {
            getAlert('FAILED', 'กรุณาเลือกสินค้าจากคลัง');
            return; // หยุดการทำงานของฟังก์ชันถ้าไม่มีการเลือกสินค้า
        }

        if (selectedType === 'OD') {
            selectedItems.forEach(od => {
                onRowSelectOd(od);
            });
        } else if (selectedType === 'ST') {
            selectedStockItems.forEach(item => {
                onRowSelectItem(item);
            });
        }

        handleItemClose();
    };

    return (
        <>
            <div
                className={`modal ${showItemModal ? 'show' : ''}`}
                style={{ display: showItemModal ? 'block' : 'none' }}
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">รายชื่อใบออเดอร์สินค้า & สินค้าในสต็อกคลัง</h5>
                            <button type="button" className="close" onClick={handleItemClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <div className="col-4">
                                    <div className="form-group mb-0 ms-3">
                                        <span className="fw-bold">ประเภทการเลือกเบิก</span>
                                        <div className="radio-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="selectedType"
                                                value="OD"
                                                id="typeOD"
                                                checked={selectedType === 'OD'}
                                                onChange={() => setSelectedType('OD')}
                                            />
                                            <label className="form-check-label" htmlFor="typeOD">ใบออเดอร์สินค้า</label>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="selectedType"
                                                value="ST"
                                                id="typeST"
                                                checked={selectedType === 'ST'}
                                                onChange={() => setSelectedType('ST')}
                                            />
                                            <label className="form-check-label me-2" htmlFor="typeST">สินค้าในคลัง</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="form-group mb-0">
                                        <span className="fw-bold">ค้นหา</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="ค้นหา ITEM_CODE หรือ ITEM_NAME"
                                            value={searchTerm || ''}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ใบออเดอร์สินค้า */}
                            {selectedType === 'OD' && (
                                <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th className="text-center" style={{ width: '1%' }}>
                                                    <input type="checkbox" onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        setSelectedItems(checked ? filteredOdDataList : []);
                                                    }} checked={filteredOdDataList.length > 0 && selectedItems.length === filteredOdDataList.length} />
                                                </th>
                                                <th className="text-center" style={{ width: '18%' }}>เลขที่เอกสาร (OD)</th>
                                                <th className="text-center" style={{ width: '30%' }}>ชื่อลูกค้า</th>
                                                <th className="text-center" style={{ width: '30%' }}>รายละเอียดเอกสาร</th>
                                                <th className="text-center" style={{ width: '12%' }}>วันที่ใบออเดอร์</th>
                                                <th className="text-center" style={{ width: '12%' }}>วันที่จัดส่ง</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredOdDataList.length > 0 ? (
                                                filteredOdDataList.map((od, index) => (
                                                    <tr
                                                        key={od.Doc_ID || index + 1}
                                                        onClick={() => { handleCheckboxChange(od); }}
                                                        style={{ cursor: 'pointer' }}>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(item => item.WHDoc_No === od.WHDoc_No)}
                                                                onChange={() => handleCheckboxChange(od)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                        <td className="text-center">{od.WHDoc_No}</td>
                                                        <td className="text-left">{od.WHDoc_Cust_Name}</td>
                                                        <td className="text-left">{od.WHDoc_Remark}</td>
                                                        <td className="text-center">{formatThaiDateUi(od.WHDoc_Date)}</td>
                                                        <td className="text-center">{formatThaiDateUi(od.WHDoc_TransDate)}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {/* สินค้าในคลัง */}
                            {selectedType === 'ST' && (
                                <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th className="text-center" style={{ width: '1%' }}>
                                                    <input type="checkbox" onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        setSelectedStockItems(checked ? filteredItemDataList : []);
                                                    }} checked={filteredItemDataList.length > 0 && selectedStockItems.length === filteredItemDataList.length} />
                                                </th>
                                                <th className="text-center" style={{ width: '18%' }}>รหัสสินค้า</th>
                                                <th className="text-center" style={{ width: '30%' }}>ชื่อสินค้า</th>
                                                <th className="text-center" style={{ width: '30%' }}>รายละเอียด</th>
                                                <th className="text-center" style={{ width: '12%' }}>จำนวน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredItemDataList.length > 0 ? (
                                                filteredItemDataList.map((item, index) => (
                                                    <tr
                                                        key={item.Item_Id || index + 1}
                                                        onClick={() => { handleStockItemCheckboxChange(item); }}
                                                        style={{ cursor: 'pointer' }}>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedStockItems.some(selectedItem => selectedItem.Item_Id === item.Item_Id)}
                                                                onChange={() => handleStockItemCheckboxChange(item)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                        <td className="text-center">{item.Item_Code}</td>
                                                        <td className="text-left">{item.Item_Name}</td>
                                                        <td className="text-left">{item.Item_Desc}</td>
                                                        <td className="text-center">{item.Item_Quantity}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5} className="text-center">ไม่พบข้อมูล</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleConfirmSelection}>ยืนยัน</button>
                            <button type="button" className="btn btn-secondary" onClick={handleItemClose}>ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemOrderModal;