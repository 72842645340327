import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TransportInformation/Main';
import InitialDataModal from '../../components/Modal/InitialDataModal';

// Utils
import {
  getAlert,
  getData,
  getCompany,
  getDataJoinWH,
} from '../../../utils/SamuiUtils';
import { InitialDataDetailModel } from '../../../model/InitialData/InitialDataDetailModel';

function TransportInformation() {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [pklList, setPklList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formData, setFormData] = useState(InitialDataDetailModel());

  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = async () => setShowModal(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime();
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      // ดึงข้อมูลตั้งต้นรถขนส่งจาก Tb_Set_WH และ Tb_Set_Car โดยการ Join 2 Table
      const masterListResponse = await getDataJoinWH();
      let masterList = [];
      if (masterListResponse && masterListResponse.length > 0) {
        masterList = masterListResponse.sort((a, b) => a.Car_id - b.Car_id);
      }

      // ดึงข้อมูลบริษัทจาก Tb_Set_Company
      const tbSetCompanyResponse = await getCompany();
      let tbSetCompany = [];
      if (tbSetCompanyResponse && tbSetCompanyResponse.length > 0) {
        tbSetCompany = tbSetCompanyResponse;
      }

      // รวมข้อมูลบริษัทเข้าไปใน masterList
      const updatedMasterList = masterList.map(item => {
        // หาข้อมูลบริษัทจาก tbSetCompany ที่มี Comp_Id ตรงกับ item.Comp_Id
        const company = tbSetCompany.find(comp => comp.Comp_Id === item.Comp_Id);
        // ถ้าพบข้อมูลบริษัท ให้รวม item เดิมกับ Comp_Name_TH ของบริษัทนั้น
        if (company) {
          return { ...item, Comp_Name_TH: company.Comp_Name_TH };
        } else {
          console.warn(`No matching company found for comp_id: ${item.Comp_Id}`);
          return item; // คืนค่า item เดิมถ้าไม่พบข้อมูลบริษัท
        }
      });

      // อัปเดต state ด้วย masterList ที่ใหม่
      setDataMasterList(updatedMasterList);
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I');
    handleShow(); // Show the modal
  };

  const onRowSelected = async (item) => {
    setMode('U');
    setFormData({
      car_id: item.Car_id,
      car_no: item.Car_No,
      car_name: item.Car_Name,
      car_remark: item.Car_Remark,
      car_status: item.Car_Status,
      wh_id: item.WH_Id,
      comp_id: item.Comp_Id,
    });

    try {
      const getPklList = await getData("PKList_H", `PK_Car_Id = ${item.Car_id}`);
      if (getPklList && getPklList.length > 0) {
        setPklList(getPklList);
      }
    } catch (error) {
      getAlert("FAILED", error)
    }
    setSelectedCar(item);
    handleShow(); // Show the modal
  };

  return (
    <div className="TransportInformation">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <Main
                masterList={dataMasterList}
                name={'ข้อมูลรถขนส่ง'}
                onPageInsert={onPageInsert}
                onRowSelected={onRowSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <InitialDataModal
        showModal={showModal}
        handleClose={handleClose}
        callInitialize={initialize}
        mode={mode}
        name={'ข้อมูลรถขนส่ง'}
        formData={formData}
        setFormData={setFormData}
        selectedCar={selectedCar}
        pklList={pklList}
      />
    </div>
  );
}

export default TransportInformation;