export const InitialDataDetailModel = () => {
    return {
        car_id: null,
        car_no: null,
        car_name: null,
        car_remark: null,
        car_status: null,
        wh_id: null,
        comp_id: window.localStorage.getItem('company'),
    };
}