import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/Creditor/Main';
import Form from '../../components/InitialData/Creditor/Form';

// Utils
import {
    getData,
    getAlert,
    getDataWithComp,
} from '../../../utils/SamuiUtils';
import { InitialDataCreditor } from '../../../model/InitialData/InitialDataCreditor';

const Creditor = () => {

    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [formData, setFormData] = useState(InitialDataCreditor());

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            setMode('S');
            fetchRealtime(); // เรียกใช้งาน fetchRealtime เพื่อโหลดข้อมูลเมื่อ component โหลดครั้งแรก
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const fetchRealtime = async () => {
        try {
            // ดึงข้อมูลจาก Tb_Set_AP ทั้งหมด
            const masterList = await getData("Tb_Set_AP", "1=1");
            if (masterList && masterList.length > 0) {
                masterList.sort((a, b) => a.AP_Id - b.AP_Id);
                setDataMasterList(masterList);
            }
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onPageInsert = () => {
        setMode('I')
    };

    const onRowSelected = (item) => {
        setMode('U');
        setFormData({
            ap_id: item.AP_Id, // Int
            comp_id: window.localStorage.getItem('company'), // Int
            ap_code: item.AP_Code,
            ap_name: item.AP_Name,
            ap_type: item.AP_Type, // Int
            ap_shortname: item.AP_ShortName,
            ap_taxno: item.AP_TaxNo,
            ap_add1: item.AP_Add1,
            ap_add2: item.AP_Add2,
            ap_add3: item.AP_Add3,
            ap_province: item.AP_Province,
            ap_zipcode: item.AP_Zipcode,
            ap_contact: item.AP_Contact,
            ap_contact2: item.AP_Contact2,
            ap_tel: item.AP_Tel,
            ap_tel2: item.AP_Tel2,
            ap_group: item.AP_Group,
            ap_refsalecode: item.AP_RefSaleCode,
            ap_area: item.AP_Area,
            ap_status: item.AP_Status,
            ap_credit: item.AP_Credit, // Int
            ap_vat: item.AP_Vat,    // Int
            ap_discount: item.AP_Discount,
            ap_branch: item.AP_Branch,
            ap_bt_name: item.AP_BT_Name,
        })
        setSelectedData(item);
    };

    return <div className="Creditor">
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {
                            mode === 'S' ? (<Main
                                masterList={dataMasterList}
                                name={'ข้อมูลเจ้าหนี้'}
                                onPageInsert={() => onPageInsert()}
                                onRowSelected={(item) => onRowSelected(item)}
                            />
                            ) : (<Form
                                callInitialize={initialize}
                                mode={mode}
                                name={'ข้อมูลเจ้าหนี้'}
                                formData={formData}
                                setFormData={setFormData}
                                selectedData={selectedData}
                            />
                            )}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Creditor;
