import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TbSetItemUnit/Main';
import InitialDataModalTbSetItemUnit from '../../components/Modal/InitialDataModalTbSetItemUnit';

// Utils
import {
  getAlert,
  getData,
} from '../../../utils/SamuiUtils';
import { InitialDataTbSetItemUnit } from '../../../model/InitialData/InitialDataTbSetItemUnit';

function TbSetItemUnit() {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formData, setFormData] = useState(InitialDataTbSetItemUnit());


  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = async () => setShowModal(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime();
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      const masterList = await getData("Tb_Set_Unit", "1=1");
      if (masterList && masterList.length > 0) {
        masterList.sort((a, b) => a.Unit_Id - b.Unit_Id);
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I');
    handleShow();
  };

  const onRowSelected = async (item) => {
    setMode('U');
    setFormData({
      unit_id: item.Unit_Id,
      unit_name: item.Unit_Name,
      unit_status: item.Unit_Status,
      comp_id: item.Comp_Id,
    });
    setSelectedCar(item);
    handleShow();
  };

  return (
    <div className="TbSetItemUnit">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <Main
                masterList={dataMasterList}
                name={'ข้อมูลหน่วย'}
                onPageInsert={onPageInsert}
                onRowSelected={onRowSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <InitialDataModalTbSetItemUnit
        showModal={showModal}
        handleClose={handleClose}
        callInitialize={initialize}
        mode={mode}
        name={'ข้อมูลหน่วย'}
        formData={formData}
        setFormData={setFormData}
        selectedCar={selectedCar}
      />
    </div>
  );
}

export default TbSetItemUnit;