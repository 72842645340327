export const InitialDataCompany = () => {
    return {
        comp_id: null, // Int
        comp_code: null,
        comp_name_th: null,
        comp_name_en: null,
        comp_shortname: null,
        comp_taxno: null,
        comp_add1: null,
        comp_add2: null,
        comp_add3: null,
        comp_province: null,
        comp_post: null,
        comp_tel1: null,
        comp_tel2: null,
        comp_contact1: null,
        comp_contact2: null,
    }
}