import moment from 'moment';

import {
    formatThaiDateUi,
    getCreateDateTime
} from '../../utils/DateUtils';

export const pkListMasterModel = () => {
    return {
        docId: null,
        compId: window.localStorage.getItem('company'),
        docNo: null,
        docDate: formatThaiDateUi(moment()),
        docStatus: parseInt("1", 10),
        reqStcStatus: parseInt("0", 10),
        deliveryLineId: null,
        pkCarId: null,
        pkCarNo: null,
        pkRound: null,
        pkDriver1Id: null,
        pkDriver2Id: null,
        pkDriver3Id: null,
        createdDate: getCreateDateTime(),
        createdByName: window.localStorage.getItem('name'),
        createdById: window.localStorage.getItem('emp_id'),
        updateDate: null,
        updateByName: null,
        updateById: null,
        cancelDate: null,
        cancelByName: null,
        cancelById: null,
        pkDocType: null
    };
};