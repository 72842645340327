import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/Employee/Main';
import Form from '../../components/InitialData/Employee/Form';

// Utils
import {
    getData,
    getAlert,
} from '../../../utils/SamuiUtils';
import { InitialDataEmployee } from '../../../model/InitialData/InitialDataEmployee';
import { formatThaiDateUi } from '../../../utils/DateUtils';

const Employee = () => {

    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [formData, setFormData] = useState(InitialDataEmployee());
    
    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            setMode('S');
            fetchRealtime();
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const fetchRealtime = async () => {
        try {
            // ดึงข้อมูลจาก Tb_Set_Employee ทั้งหมด
            const masterList = await getData("Tb_Set_Employee", "1=1");
            if (masterList && masterList.length > 0) {
                masterList.sort((a, b) => a.Emp_Id - b.Emp_Id);
                setDataMasterList(masterList);
            }
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onPageInsert = () => {
        setMode('I')
    };

    const onRowSelected = (item) => {
        setMode('U');
        setFormData({
            emp_id: item.Emp_Id,
            emp_code: item.Emp_Code,
            emp_prefix: item.Emp_Prefix,
            emp_name: item.Emp_Name,
            emp_dept: item.Emp_Dept,
            emp_position: item.Emp_Position,
            emp_position_detail: item.Emp_Position_Detail,
            emp_startdate: formatThaiDateUi(item.Emp_StartDate),
            emp_tel: item.Emp_Tel,
            emp_status: item.Emp_Status,
            emp_uuser: item.Emp_UUser,
            emp_ppass: item.Emp_PPass,
            emp_pin: item.Emp_PIN,
            gold_so: item.Gold_SO,
            comp_id: item.Comp_Id,
        })
        setSelectedData(item);
    };

    return <div className="Creditor">
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {
                            mode === 'S' ? (<Main
                                masterList={dataMasterList}
                                name={'ข้อมูลพนักงาน'}
                                onPageInsert={() => onPageInsert()}
                                onRowSelected={(item) => onRowSelected(item)}
                            />
                            ) : (<Form
                                callInitialize={initialize}
                                mode={mode}
                                name={'ข้อมูลพนักงาน'}
                                formData={formData}
                                setFormData={setFormData}
                                selectedData={selectedData}
                            />
                            )}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Employee;
