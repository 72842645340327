import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// React DateTime
import Datetime from 'react-datetime';
import moment from 'moment';

// Components
import Breadcrumbs from "../../Breadcrumbs";
// import ItemWhTable from '../../Content/ItemWhTable';
// import PkModal from '../../Modal/PkModal';
import ItemOrderTable from '../../Content/ItemOrderTable';
import FormAction from '../../Actions/FormAction';

// Model
import { whDocMasterModel } from '../../../../model/Warehouse/WHDocMasterModel';
import { whDocDetailModel } from '../../../../model/Warehouse/WHDocDetailModel';

import {
  getData,
  getDataWithComp,
  getAlert,
  getMaxWdNo,
  deleteDetail
} from "../../../../utils/SamuiUtils";

// import {
//   manageWhItemData
// } from '../../../../utils/WarehouseUtils';

import {
  formatDateTime,
  formatThaiDateUi,
  formatDateOnChange,
  formatStringDateToDate,
  formatThaiDateUiToDate,
  setCreateDateTime
} from "../../../../utils/DateUtils";

const Form = ({ callInitialize, mode, name, whDocNo, pickingList }) => {
  const [formMasterList, setFormMasterList] = useState(whDocMasterModel());
  const [formDetailList, setFormDetailList] = useState([]);
  const [itemDataList, setItemDataList] = useState([]);
  const [whFromDataList, setWhFromDataList] = useState([]);
  const [whToDataList, setWhToDataList] = useState([]);
  // const [pkDataList, setPkDataList] = useState([]);
  const [odDataList, setOdDataList] = useState([]);

  // การเลือกคลังจากหน้าจอ
  const [selectedWarehouseFrom, setSelectedWarehouseFrom] = useState(1);
  const [selectedWarehouseTo, setSelectedWarehouseTo] = useState(null);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const whFromDataList = await getDataWithComp('Tb_Set_WH', `AND Flag_Vansale = 'N' ORDER BY WH_Code ASC`);
    if (whFromDataList && whFromDataList.length > 0) {
      setWhFromDataList(whFromDataList);
    }

    const whToDataList = await getDataWithComp('Tb_Set_WH', `AND Flag_Vansale = 'Y' ORDER BY WH_Code ASC`);
    if (whToDataList && whToDataList.length > 0) {
      setWhToDataList(whToDataList);
    }

    // const pkDataList = await getDataWithComp('API_0801_PKList_H', 'ORDER BY Doc_No DESC');
    // if (pkDataList && pkDataList.length > 0) {
    //   setPkDataList(pkDataList);
    // }

    // ค้นหาใบออเดอร์ ที่ยังไม่เคยถูกนำไปสร้างใบเบิก
    const odDataList = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-od-by-comp-id`, {
      comp_id: window.localStorage.getItem('company')
    }, {
      headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
    });
    if (odDataList.data && odDataList.data.length > 0) {
      setOdDataList(odDataList.data);
    }

    // ใบเบิก จากคลังให้ Default เป็นคลังก่อนการผลิต
    await handleChangeWarehouse(1, 'FROM');

    // ถ้ากดมาจาก Picking List
    if (mode === 'I' && window.location.pathname === '/picking-list') {
      // setFormMasterList({
      //   ...formMasterList,
      //   refDocId: pickingList.docId,
      //   refDoc: pickingList.docNo,
      //   refDocDate: pickingList.docDate
      // });

      const getWarehouseByCarNo = await getDataWithComp('Tb_Set_WH', `AND WH_Code = '${pickingList.pkCarNo}'`);
      if (getWarehouseByCarNo && getWarehouseByCarNo.length > 0) {
        setSelectedWarehouseTo(getWarehouseByCarNo[0].WH_Id);
      }

      // ถ้าเป็นใบขาย ให้แสดงไอเทมตามที่ลูกค้ามี
      // const pkListDetails = await getData('PKList_D', `Doc_Id = '${pickingList.docId}'`);

      // for (const result of pkListDetails) {
      //   if (result.SO_Id != null) {
      //     let findSoDetail = await getData('SO_D', `Doc_ID = '${result.SO_Id}'`);

      //     for (const detail of findSoDetail) {

      //       let itemOnHand = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(detail.WH_Id)} AND Item_Id = ${Number(detail.Item_Id)}`);

      //       setFormDetailList(prevFormDetailList => {
      //         // ตรวจสอบว่ามีไอเทมนี้ใน formDetailList หรือไม่
      //         const existingItemIndex = prevFormDetailList.findIndex(item => item.itemId === detail.Item_Id);

      //         if (existingItemIndex > -1) {
      //           // ถ้าเจอไอเทมที่ซ้ำกัน ให้เพิ่มจำนวน qty เข้าไปในรายการที่มีอยู่
      //           const updatedItem = {
      //             ...prevFormDetailList[existingItemIndex],
      //             itemOrder: prevFormDetailList[existingItemIndex].itemOrder + detail.Item_Qty,
      //             itemBalance: prevFormDetailList[existingItemIndex].itemBalance + detail.Item_Qty
      //           };

      //           // อัปเดตรายการเดิมใน formDetailList
      //           return [
      //             ...prevFormDetailList.slice(0, existingItemIndex),
      //             updatedItem,
      //             ...prevFormDetailList.slice(existingItemIndex + 1)
      //           ];
      //         } else {
      //           // ถ้าไม่เจอไอเทม ให้สร้างรายการใหม่
      //           let newRow = whDocDetailModel(prevFormDetailList.length + 1);

      //           return [
      //             ...prevFormDetailList,
      //             {
      //               ...newRow,
      //               line: null,
      //               itemId: detail.Item_Id,
      //               itemCode: detail.Item_Code,
      //               itemName: detail.Item_Name,
      //               itemUnit: detail.Item_Unit,
      //               itemOnHand: itemOnHand[0].Item_Onhand,
      //               itemOrder: detail.Item_Qty || 0,
      //               itemBalance: itemOnHand[0].Item_Onhand,
      //               itemPriceUnit: detail.Item_Price_Unit,
      //               itemTotal: detail.Item_Total,
      //               docType: null
      //             }
      //           ];
      //         }
      //       });
      //     }
      //   }
      // }
    }

    // สำหรับ View เข้ามาเพื่อแก้ไขข้อมูล
    if (mode === 'U') {
      await getModelByNo();
    }
  };

  const getModelByNo = async () => {
    try {
      const fromDatabase = await getDataWithComp('WHDoc_H', `AND WHDoc_No = '${whDocNo}'`);

      if (!fromDatabase) {
        throw new Error("ไม่พบข้อมูลเอกสาร");
      }

      // ฟังก์ชันดึงข้อมูล itemOnHand
      const getOnHand = async (whId, itemId) => {
        const itemOnHand = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(whId)} AND Item_Id = ${Number(itemId)}`);
        return itemOnHand[0].Item_Onhand;
      };

      // ฟังก์ชันสร้างแถวใหม่
      const createNewRow = (index, itemSelected, itemOnHand) => {
        return {
          ...whDocDetailModel(index + 1),
          dtId: itemSelected.DT_Id,
          whDocId: itemSelected.WHDoc_Id || null,
          line: itemSelected.Line || null,
          itemId: itemSelected.Item_Id || null,
          itemCode: itemSelected.Item_Code || null,
          itemName: itemSelected.Item_Name || null,
          itemOnHand: itemOnHand || null,
          itemQty: itemSelected.Item_Qty || null,
          itemIncrease: null,
          itemDecrease: itemSelected.Item_Qty,
          itemOrder: null,
          itemBalance: Number(itemOnHand) - Number(itemSelected.Item_Qty),
          itemUnit: itemSelected.Item_Unit || null,
          itemPriceUnit: itemSelected.Item_Price_Unit || null,
          itemDiscount: null,
          itemDisType: null,
          itemTotal: itemSelected.Item_Total || null,
          docType: itemSelected.Doc_Type || null,
          refDocId: itemSelected.Ref_DocID || null,
          refDocNo: itemSelected.Ref_DocNo || null
        };
      };

      // ค้นหาข้อมูลของ Detail ด้วย WHDoc_Id
      const fromDetail = await getDataWithComp('API_1002_WHDOC_D', `AND WHDoc_Id = ${fromDatabase[0].WHDoc_Id} ORDER BY Line ASC`);

      if (fromDetail.length > 0) {
        // สร้าง Promises สำหรับ itemOnHand
        const itemOnHandPromises = fromDetail.map(item => getOnHand(fromDatabase[0].F_WH_Id, item.Item_Id));

        // รอ Promises
        const itemOnHandResults = await Promise.all(itemOnHandPromises);

        // สร้าง newFormDetails โดยใช้ itemOnHandResults
        const newFormDetails = fromDetail.map((item, index) => createNewRow(formDetailList.length + index, item, itemOnHandResults[index]));

        await handleChangeWarehouse(String(fromDatabase[0].F_WH_Id), 'FROM');
        await handleChangeWarehouse(String(fromDatabase[0].T_WH_Id), 'TO');
        setFormDetailList(newFormDetails);

        const data = fromDatabase[0];

        setFormMasterList({
          whDocId: data.WHDoc_Id,
          whDocNo: data.WHDoc_No,
          whDocType: data.WHDoc_Type,
          whDocCustId: data.WHDoc_Cust_Id,
          whDocCustCode: null,
          whDocCustName: null,
          whDocDate: formatThaiDateUi(data.WHDoc_Date),
          whDocTransDate: formatThaiDateUi(data.WHDoc_TransDate),
          whDocCreatedDate: setCreateDateTime(data.WHDoc_CreatedDate),
          whDocCreatedBy: data.WHDoc_CreatedBy,
          whDocStatus: data.WHDoc_Status,
          whDocSeq: data.WHDoc_SEQ,
          whDocRemark: data.WHDoc_Remark,
          whDocCompId: data.Comp_Id,
          whDocShowFront: data.WHDoc_ShowFront,
          refDocId: data.Ref_DocID,
          refDoc: null,
          refDocDate: null,

          // ใช้สำหรับแสดงเฉยๆ
          arAdd1: null,
          arAdd2: null,
          arAdd3: null,
          arProvince: null,
          arZipcode: null,
          arTaxNo: null,
        });

        // ค้นหาเลขใบออเดอร์ที่อยู่ใน newFormDetails
        const refDocNos = newFormDetails.map(item => item.refDocNo);

        // Group เลขที่ซ้ำกัน และต้องไม่เอาที่ refDocNos === NULL
        const refDocNo = refDocNos.filter((item, index) => refDocNos.indexOf(item) === index && item !== null);

        // สร้าง string สำหรับคำสั่ง SQL
        if (refDocNo.length > 0) {
          const refDocNoString = refDocNo.map(docNo => `'${docNo}'`).join(', ');
          const findNewRefDoc = await getDataWithComp('API_1001_WHDOC_H', `AND WHDoc_No IN (${refDocNoString})`);

          // Update ของที่เจอเข้าไปใน odDataList และเก็บค่าเดิมไว้ด้วย
          setOdDataList(prevOdDataList => [
            ...prevOdDataList,  // เก็บค่าเดิมไว้
            ...findNewRefDoc   // เพิ่มผลลัพธ์ใหม่เข้าไป
          ]);
        }

      } else {
        getAlert('FAILED', `ไม่พบข้อมูลที่ตรงกับเลขที่เอกสาร ${fromDatabase[0].WHDoc_No} กรุณาตรวจสอบและลองอีกครั้ง`);
      }
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  const handleChangeMaster = (e) => {
    const { name, value } = e.target;
    // อัปเดตค่าใน formMasterList
    setFormMasterList((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeDateMaster = (value, name) => {
    // ตรวจสอบว่า value เป็น moment object หรือไม่
    const newValue = value && value instanceof moment ? value.format('YYYY-MM-DD') : value;
    // อัปเดตค่าใน formMasterList
    setFormMasterList((prev) => ({
      ...prev,
      [name]: formatDateOnChange(newValue),
    }));
  };

  const handleChangeWarehouse = async (value, type) => {
    if (type === 'FROM') {

      // เคลียค่าตอนกดย้ายคลัง
      if (mode === 'I') {
        setFormDetailList([]);
      }

      const itemDataList = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(value)} ORDER BY Item_Code ASC`);
      if (itemDataList && itemDataList.length > 0) {
        setItemDataList(itemDataList);
      }
      setSelectedWarehouseFrom(value);
    } else {
      setSelectedWarehouseTo(value);
    }
  };

  const handleQuantityChange = (index, type, value, onHand) => {
    // ดักค่าเพื่อให้ส่งมาอย่างถูกต้อง (จริงๆแล้วแทบไม่ได้ใช้)
    if (type !== 'decrease') {
      getAlert("FAILED", "พบข้อผิดพลาดในการดำเนินการ กรุณารีเฟชรแล้วลองใหม่");
    }

    // ตรวจสอบว่าค่าที่กรอกเข้ามาเป็นตัวเลขหรือตัวเลขที่มีจุดทศนิยม
    if (!/^\d*\.?\d*$/.test(value)) {
      //getAlert("FAILED", "กรุณากรอกเฉพาะตัวเลขเท่านั้น");
      return;
    }

    const updatedList = [...formDetailList];

    // ดักไม่ให้ปรับปรุงเกินกว่าสินค้าคงเหลือ เฉพาะตอนปรับลดสินค้า
    if (value > onHand) {
      getAlert("FAILED", "กรุณาระบุจำนวนไม่เกินคงเหลือ");
      updatedList[index].itemDecrease = null;
      updatedList[index].docType = "TO";
      updatedList[index].itemBalance = Number(onHand);
      setFormDetailList(updatedList);
      return;
    }

    updatedList[index].docType = "TO";
    updatedList[index].itemDecrease = Number(value);
    updatedList[index].itemBalance = Number(onHand) - Number(value);
    updatedList[index].itemQty = Number(value);

    setFormDetailList(updatedList);
  };

  // SET ITEM FROM ORDER & SET ITEM
  const [showItemModal, setShowItemModal] = useState(false);
  const handleItemShow = () => setShowItemModal(true);
  const handleItemClose = () => setShowItemModal(false);
  const onRowSelectItem = (itemsSelected) => {
    try {
      // ถ้าค่าที่ส่งเข้ามาไม่ใช่ array ให้ทำให้เป็น array
      const items = Array.isArray(itemsSelected) ? itemsSelected : [itemsSelected];

      // เก็บรายการสินค้าใหม่ที่จะเพิ่มเข้าไปใน formDetailList
      const newItems = [];

      // วนลูปผ่านสินค้าที่เลือกหลายรายการ
      items.forEach((itemSelected) => {
        // ตรวจสอบว่า Item_Id ซ้ำกันเฉพาะในกรณีที่ refDocId === null
        const existingItemIndex = formDetailList.findIndex(
          (item) => item.refDocId === null && item.itemId === itemSelected.Item_Id
        );

        const newRow = whDocDetailModel(formDetailList.length + 1);

        if (existingItemIndex !== -1) {
          // ถ้าพบ itemId ซ้ำใน formDetailList ให้แทนที่ค่าในรายการ
          formDetailList[existingItemIndex] = {
            ...newRow,
            line: null,
            itemId: itemSelected.Item_Id,
            itemCode: itemSelected.Item_Code,
            itemName: itemSelected.Item_Name,
            itemUnit: itemSelected.Item_Unit_ST,
            itemOnHand: itemSelected.Item_Onhand || 0,
            itemBalance: itemSelected.Item_Onhand || 0,
            itemPriceUnit: 0,
            itemTotal: 0,
            docType: "TO"
          };
        } else {
          // ถ้าไม่พบ itemId ซ้ำ ให้เพิ่มรายการใหม่
          newItems.push({
            ...newRow,
            line: null,
            itemId: itemSelected.Item_Id,
            itemCode: itemSelected.Item_Code,
            itemName: itemSelected.Item_Name,
            itemUnit: itemSelected.Item_Unit_ST,
            itemOnHand: itemSelected.Item_Onhand || 0,
            itemBalance: itemSelected.Item_Onhand || 0,
            itemPriceUnit: 0,
            itemTotal: 0,
            docType: "TO"
          });
        }
      });

      // ตรวจสอบว่ามีรายการใหม่ที่จะเพิ่มหรือไม่
      if (newItems.length > 0) {
        setFormDetailList((prevFormDetailList) => [...prevFormDetailList, ...newItems]);
      } else {
        // หากไม่มีรายการใหม่ เพิ่มค่าที่ถูกแทนที่เข้าไปใน formDetailList
        setFormDetailList((prevFormDetailList) => [...prevFormDetailList]);
      }

      handleItemClose(); // ปิด modal หลังจากเลือกสินค้าเสร็จสิ้น
    } catch (error) {
      getAlert("FAILED", error);
    }
  };
  const onRowSelectOd = async (odSelected) => {
    try {
      // เช็กว่ามี WHDoc_Id อยู่ใน formDetailList แล้วหรือไม่
      const isWHDocExists = formDetailList.some(item => item.refDocId === odSelected.WHDoc_Id);

      if (isWHDocExists) return; // ถ้ามีอยู่แล้ว ให้ return ออกจากฟังก์ชัน

      // ค้นหาข้อมูลสินค้าที่เลือกจาก API_1002_WHDOC_D
      const itemFromOd = await getDataWithComp('API_1002_WHDOC_D', `AND WHDoc_Id = ${Number(odSelected.WHDoc_Id)} ORDER BY Line ASC`);

      if (itemFromOd && itemFromOd.length > 0) {
        // ใช้ Promise.all เพื่อรอผลลัพธ์จาก getOnHand ทั้งหมดก่อน
        const newFormDetailList = await Promise.all(itemFromOd.map(async (item, index) => {

          // ค้นหา ItemOnHand จาก API_1101_WH_ITEM_ONHAND
          const getOnHand = async (itemId) => {
            const itemOnHand = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(selectedWarehouseFrom)} AND Item_Id = ${Number(itemId)}`);
            return itemOnHand[0]?.Item_Onhand || 0;
          };

          const newRow = whDocDetailModel(index + 1);
          return {
            ...newRow,
            line: item.Line,
            itemId: item.Item_Id,
            itemCode: item.Item_Code,
            itemName: item.Item_Name,
            itemUnit: item.Item_Unit,
            itemOnHand: Number(await getOnHand(item.Item_Id)),
            itemQty: item.Item_Qty || 0,
            itemDecrease: item.Item_Qty || 0,
            itemBalance: Number(await getOnHand(item.Item_Id)) - Number((item.Item_Qty || 0)),
            itemPriceUnit: 0,
            itemTotal: 0,
            docType: "TO",
            refDocId: Number(odSelected.WHDoc_Id),
            refDocNo: odSelected.WHDoc_No
          };
        }));

        setFormDetailList(prevFormDetailList => [...prevFormDetailList, ...newFormDetailList]);
      }

      handleItemClose();
    } catch (error) {
      getAlert("FAILED", error);
    }
  };
  const handleRemoveRow = (index, refDocNo) => {
    if (refDocNo !== null && refDocNo !== '') {
      // ลบทุก row ที่มี refDocNo เหมือนกัน
      const newList = formDetailList.filter(item => item.refDocNo !== refDocNo);
      setFormDetailList(newList);
    } else {
      // ลบเฉพาะ row ที่ตรงกับ index
      const newList = formDetailList.filter((_, i) => i !== index);
      setFormDetailList(newList);
    }
  };

  // SET PK
  // const [showPkModal, setShowPkModal] = useState(false);
  // const handlePkShow = () => setShowPkModal(true);
  // const handlePkClose = () => setShowPkModal(false);
  // const onRowSelectPk = async (pkSelected) => {
  //   try {
  //     // เคลียร์ค่าใน formMasterList และ formDetailList
  //     setFormMasterList({});
  //     setFormDetailList([]);

  //     // const [fromViewDetail] = await Promise.all([
  //     //   getDataWithComp("API_0802_PKList_D", "ORDER BY DL_Queue ASC")
  //     // ]);

  //     setFormMasterList({
  //       ...formMasterList,
  //       refDocId: pkSelected.Doc_Id,
  //       refDoc: pkSelected.Doc_No,
  //       refDocDate: formatThaiDateUi(pkSelected.Doc_Date)
  //     });

  //     // const newRow = whDocDetailModel(formDetailList.length + 1);

  //     // setFormDetailList([
  //     //   ...formDetailList,
  //     //   {
  //     //     ...newRow,
  //     //     line: null,
  //     //     itemId: itemSelected.Item_Id,
  //     //     itemCode: itemSelected.Item_Code,
  //     //     itemName: itemSelected.Item_Name,
  //     //     itemUnit: itemSelected.Item_Unit_ST,
  //     //     itemOnHand: itemSelected.Item_Onhand || 0,
  //     //     itemBalance: itemSelected.Item_Onhand || 0,
  //     //     itemPriceUnit: 0,
  //     //     itemTotal: 0,
  //     //     docType: null
  //     //   }
  //     // ]);

  //     handlePkClose(); // ปิด modal หลังจากเลือก
  //   } catch (error) {
  //     getAlert("FAILED", error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const findMaxWdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '3' ORDER BY WHDoc_No DESC`);
      const maxWd = getMaxWdNo(findMaxWdNo);
      let newMaxWd = maxWd;

      // if (formMasterList.refDocId === null) {
      //   getAlert("FAILED", "กรุณาเลือกอ้างอิงเอกสาร");
      //   return;
      // }
      if (selectedWarehouseFrom === null) {
        getAlert("FAILED", "กรุณาเลือกจากคลัง");
        return;
      }
      if (selectedWarehouseTo === null) {
        getAlert("FAILED", "กรุณาเลือกไปรถส่งสินค้า");
        return;
      }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มรายละเอียดสินค้าที่จะเบิก");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // ตรวจสอบค่าภายใน formDetailList
      for (const item of formDetailList) {
        if (!item.itemQty || parseInt(item.itemQty) === 0) {
          getAlert("FAILED", `กรุณากรอกจำนวนเบิกของสินค้า ${item.itemName}`);
          return; // หยุดการทำงานหากจำนวนของสินค้าเป็น 0 หรือไม่มีค่า
        }

        // ถ้าเป็นการเปิด Modal จาก PickingList
        // if (window.location.pathname === '/picking-list') {
        //   if (parseInt(item.itemBalance) !== 0) {
        //     const isConfirmed = await new Promise((resolve) => {
        //       getAlert("C_DIALOG", `จำนวนสินค้า ${item.itemName} ไม่ครบตามคำสั่งซื้อ ท่านยืนยันที่จะบันทึกข้อมูลนี้หรือไม่?`, resolve);
        //     });

        //     if (!isConfirmed) {
        //       return; // หยุดการทำงานเมื่อผู้ใช้กดยกเลิก
        //     }
        //   }
        // }
      }

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        wh_doc_no: newMaxWd,
        wh_doc_type: parseInt("3", 10),
        wh_doc_cust_id: parseInt(formMasterList.whDocCustId, 10),
        wh_doc_date: formatStringDateToDate(formMasterList.whDocDate),
        wh_doc_trans_date: formatStringDateToDate(formMasterList.whDocTransDate),
        wh_doc_created_date: formatThaiDateUiToDate(formMasterList.whDocCreatedDate),
        wh_doc_created_by: formMasterList.whDocCreatedBy,
        wh_doc_status: parseInt("1", 10),
        wh_doc_seq: formatDateTime(new Date()),
        wh_doc_remark: formMasterList.whDocRemark,
        wh_doc_comp_id: formMasterList.whDocCompId,
        wh_doc_show_front: null,
        ref_doc_id: formMasterList.refDocId,
        f_wh_id: selectedWarehouseFrom,
        f_zone_id: formMasterList.fZoneId,
        f_lt_id: formMasterList.fLtId,
        t_wh_id: selectedWarehouseTo,
        t_zone_id: formMasterList.fZoneId,
        t_lt_id: formMasterList.fLtId
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        const getWhDocIdResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-by-wh-doc-no`, {
          table: 'WHDoc_H',
          wh_doc_no: formMasterData.wh_doc_no
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (getWhDocIdResponse && getWhDocIdResponse.data.length > 0) {
          const whDocId = parseInt(getWhDocIdResponse.data[0].WHDoc_Id, 10);
          let line = 1;

          const detailPromises = formDetailList.map(async (item) => {
            const formDetailData = {
              wh_doc_id: whDocId,
              line: line,
              item_id: item.itemId,
              item_code: item.itemCode,
              item_name: item.itemName,
              item_qty: item.itemQty,
              item_unit: item.itemUnit,
              item_price_unit: item.itemPriceUnit,
              item_total: item.itemTotal,
              doc_type: item.docType,
              ref_doc_id: item.refDocId
            };
            line++;

            // // ย้ายออก
            // await manageWhItemData(
            //   item.itemId, // Item ไอดีของสิ่งนั้นๆ
            //   item.itemCode, // Item โค้ดของสิ่งนั้นๆ
            //   item.itemName, // ชื่อ Item นั้นๆ
            //   selectedWarehouseFrom, // WarehouseID ของไอเทม
            //   "TO", // ประเภท เช่น IN/OUT/AI/AO/TI/TO
            //   Number(item.itemQty), // ค่าจำนวนที่กรอกจากหน้าจอ
            //   whDocId, // ไอดีเอกสาร
            //   newMaxWd, // เลขเอกสาร
            //   formMasterList.refDoc, // เลขเอกสารอ้างอิง
            //   formMasterList.whDocRemark // หมายเหตุ
            // );

            // // ย้ายเข้า
            // await manageWhItemData(
            //   item.itemId, // Item ไอดีของสิ่งนั้นๆ
            //   item.itemCode, // Item โค้ดของสิ่งนั้นๆ
            //   item.itemName, // ชื่อ Item นั้นๆ
            //   selectedWarehouseTo, // WarehouseID ของไอเทม
            //   "TI", // ประเภท เช่น IN/OUT/AI/AO/TI/TO
            //   Number(item.itemQty), // ค่าจำนวนที่กรอกจากหน้าจอ
            //   whDocId, // ไอดีเอกสาร
            //   newMaxWd, // เลขเอกสาร
            //   formMasterList.refDoc, // เลขเอกสารอ้างอิง
            //   formMasterList.whDocRemark // หมายเหตุ
            // );

            return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-d`, formDetailData, {
              headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });
          });

          await Promise.all(detailPromises);
        }

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      // if (formMasterList.refDocId === null) {
      //   getAlert("FAILED", "กรุณาเลือกอ้างอิงเอกสาร");
      //   return;
      // }
      if (selectedWarehouseFrom === null) {
        getAlert("FAILED", "กรุณาเลือกจากคลัง");
        return;
      }
      if (selectedWarehouseTo === null) {
        getAlert("FAILED", "กรุณาเลือกไปรถส่งสินค้า");
        return;
      }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มรายละเอียดสินค้าที่จะเบิก");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // ตรวจสอบค่าภายใน formDetailList
      for (const item of formDetailList) {
        if (!item.itemQty || parseInt(item.itemQty) === 0) {
          getAlert("FAILED", `กรุณากรอกจำนวนเบิกของสินค้า ${item.itemName}`);
          return; // หยุดการทำงานหากจำนวนของสินค้าเป็น 0 หรือไม่มีค่า
        }
      }

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        wh_doc_no: formMasterList.whDocNo,
        wh_doc_type: formMasterList.whDocType,
        wh_doc_cust_id: parseInt(formMasterList.whDocCustId, 10),
        wh_doc_date: formatStringDateToDate(formMasterList.whDocDate),
        wh_doc_trans_date: formatStringDateToDate(formMasterList.whDocTransDate),
        wh_doc_created_date: formatThaiDateUiToDate(formMasterList.whDocCreatedDate),
        wh_doc_created_by: formMasterList.whDocCreatedBy,
        wh_doc_status: parseInt("1", 10),
        wh_doc_seq: formatDateTime(new Date()),
        wh_doc_remark: formMasterList.whDocRemark,
        wh_doc_comp_id: formMasterList.whDocCompId,
        wh_doc_show_front: null,
        ref_doc_id: formMasterList.refDocId,
        f_wh_id: selectedWarehouseFrom,
        f_zone_id: formMasterList.fZoneId || null,
        f_lt_id: formMasterList.fLtId || null,
        t_wh_id: selectedWarehouseTo,
        t_zone_id: formMasterList.fZoneId || null,
        t_lt_id: formMasterList.fLtId || null
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-wh-doc-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {

        // ลบข้อมูลเดิมก่อนจะเริ่มการบันทึกใหม่
        await deleteDetail('WHDoc_D', `WHERE WHDoc_Id = ${formMasterList.whDocId}`);

        const whDocId = parseInt(formMasterList.whDocId, 10);
        let line = 1;

        const detailPromises = formDetailList.map(async (item) => {
          const formDetailData = {
            wh_doc_id: whDocId,
            line: line,
            item_id: item.itemId,
            item_code: item.itemCode,
            item_name: item.itemName,
            item_qty: item.itemQty,
            item_unit: item.itemUnit,
            item_price_unit: item.itemPriceUnit,
            item_total: item.itemTotal,
            doc_type: item.docType,
            ref_doc_id: item.refDocId
          };
          line++;

          return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-d`, formDetailData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });
        });

        await Promise.all(detailPromises);

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleCancel = async () => {
    console.debug("Begin handleCancel");
  };

  return <>
    <div hidden={window.location.pathname === '/picking-list'}>
      <Breadcrumbs page={whDocNo} items={[
        { name: 'คลังสินค้า', url: '/Warehouse' },
        { name: name, url: '/bill-of-lading' },
        { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' }
      ]} />
    </div>

    <div className="body">
      <div class="container-fluid my-4">
        <h5 className="">ใบเบิกสินค้า</h5>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่เอกสาร : </label>
              <Datetime
                className="input-spacing-input-date"
                name="whDocDate"
                value={formMasterList.whDocDate || null}
                onChange={(date) => handleChangeDateMaster(date, 'whDocDate')}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ readOnly: true, disabled: mode === 'U' }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label>จากคลัง : </label>
              <select
                class="form-select"
                value={selectedWarehouseFrom}
                onChange={(e) => handleChangeWarehouse(e.target.value, 'FROM')}>
                <option value={null}>
                  กรุณาระบุคลัง
                </option>
                {whFromDataList.map((warehouse) => (
                  <option
                    key={warehouse.WH_Id}
                    value={warehouse.WH_Id}
                  >
                    {warehouse.WH_Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label>สร้างโดย : </label>
              <input
                type="text"
                className="form-control input-spacing"
                value={formMasterList.whDocCreatedBy}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่จัดส่ง : </label>
              <Datetime
                className="input-spacing-input-date"
                name="whDocTransDate"
                value={formMasterList.whDocTransDate || null}
                onChange={(date) => handleChangeDateMaster(date, 'whDocTransDate')}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ readOnly: true, disabled: false }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label>ไปรถส่งสินค้า :</label>
              <select
                class="form-select"
                value={selectedWarehouseTo}
                onChange={(e) => handleChangeWarehouse(e.target.value, 'TO')}>
                <option value={null}>
                  กรุณาระบุคลัง
                </option>
                {whToDataList.map((warehouse) => (
                  <option
                    key={warehouse.WH_Id}
                    value={warehouse.WH_Id}
                  >
                    {warehouse.WH_Code} ({warehouse.WH_Name})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label className="">วันที่สร้าง : </label>
              <input
                type="text"
                className="form-control input-spacing"
                value={formMasterList.whDocCreatedDate}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>อ้างอิงเอกสาร : </label>
              <input
                type="text"
                className="form-control input-spacing"
                name="refDoc"
                value={formMasterList.refDoc || ''}
                onChange={handleChangeMaster}
                disabled={true}
              />
              {/* <div className="input-group">
                <input
                  type="text"
                  className="form-control input-spacing"
                  name="refDoc"
                  value={formMasterList.refDoc || ''}
                  onChange={handleChangeMaster}
                  disabled={true}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={handlePkShow}
                  disabled={true}>
                  <i className="fas fa-search" />
                </button>
                <PkModal
                  showPkModal={showPkModal}
                  handlePkClose={handlePkClose}
                  pkDataList={pkDataList}
                  onRowSelectPk={onRowSelectPk}
                />
              </div> */}
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label>หมายเหตุ : </label>
              <input
                type="text"
                className="form-control input-spacing"
                name="whDocRemark"
                value={formMasterList.whDocRemark || ''}
                onChange={handleChangeMaster}
                disabled={false} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่เอกสารอ้างอิง : </label>
              <input
                //type="date"
                type="text"
                className="form-control input-spacing"
                name="refDocDate"
                value={formMasterList.refDocDate || ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-4" />
        </div>
      </div>
      <hr />
    </div>
    <div className="container-fluid my-3">
      <div className="row">
        <div className="col-12">
          {/* <ItemWhTable
            mode={mode}
            showItemModal={showItemModal}
            handleItemClose={handleItemClose}
            itemDataList={itemDataList}
            onRowSelectItem={onRowSelectItem}
            formDetailList={formDetailList}
            handleItemShow={handleItemShow}
            handleQuantityChange={handleQuantityChange}
            handleRemoveRow={handleRemoveRow}
            formatCurrency={formatCurrency}
            disabled={selectedWarehouseFrom === null}
          /> */}
          <ItemOrderTable
            mode={mode}
            showItemModal={showItemModal}
            handleItemClose={handleItemClose}
            itemDataList={itemDataList}
            onRowSelectItem={onRowSelectItem}
            formDetailList={formDetailList}
            handleItemShow={handleItemShow}
            handleQuantityChange={handleQuantityChange}
            handleRemoveRow={handleRemoveRow}
            formatThaiDateUi={formatThaiDateUi}
            odDataList={odDataList}
            onRowSelectOd={onRowSelectOd}
          />
          <FormAction
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            onCancel={handleCancel}
            mode={mode}
            disabled={false}
            footer={window.location.pathname === '/picking-list' ? false : true}
          />
        </div>
      </div>
    </div>
  </>
};

export default Form;