import React from 'react';
import './../../../assets/css/purchase/datatable.css';
import { formatThaiDateUi } from '../../../utils/DateUtils';

const DataTableInitialTbSetItem = ({ currentItems, onRowSelected, currentPage, handlePageChange, dataMasterList, itemsPerPage, fieldMappings }) => {
    const renderPageNumbers = () => {
        const totalPages = Math.ceil(dataMasterList.length / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <a href="#" className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className='col-12'>
            <div className="card">
                <div className="table-responsive">
                    <table id="basic-datatables" className="table table-striped table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th className="text-center" style={{ width: '2%' }}>#</th>
                                <th className="text-center" style={{ width: '8%' }}>Item_Barcode</th>
                                <th className="text-center" style={{ width: '10%' }}>Item_Code</th>
                                <th className="text-center" style={{ width: '19%' }}>Item_Name</th>
                                <th className="text-center" style={{ width: '3%' }}>Item_Name_EN</th>
                                <th className="text-center" style={{ width: '3%' }}>Item_Short_Name</th>
                                <th className="text-center" style={{ width: '18%' }}>Item_Unit_IN</th>
                                <th className="text-center" style={{ width: '8%' }}>Item_Unit_ST</th>
                                <th className="text-center" style={{ width: '8%' }}>Item_Unit_OUT</th>
                                <th className="text-center" style={{ width: '5%' }}>Item_Cost</th>
                                <th className="text-center" style={{ width: '5%' }}>Item_Price</th>
                                <th className="text-center" style={{ width: '5%' }}>Item_PriceA</th>
                                <th className="text-center" style={{ width: '5%' }}>Item_PriceB</th>
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Series</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Group</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Cat</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Colour</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Size</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Status</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_PoPoint</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_MaxOnhand</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>AP_Code</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Gold</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_Onhand</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>Item_WH</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={index} onClick={() => onRowSelected(item)} style={{ cursor: 'pointer' }}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item[fieldMappings.ItemBarcode]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemCode]}</td>
                                        <td className="text-left">{item[fieldMappings.ItemName]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemNameEn]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemShortName]}</td>
                                        <td className="text-left">{item[fieldMappings.ItemUnitIn]}</td>
                                        <td className="text-center">{formatThaiDateUi(item[fieldMappings.ItemUnitSt])}</td>                                        <td className="text-center">{item[fieldMappings.EmpTel]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemUnitOut]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemCost]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemPrice]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemPriceA]}</td>
                                        <td className="text-center">{item[fieldMappings.ItemPriceB]}</td>
                                        {/* <td className="text-center">{item[fieldMappings.ItemBrand]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemSeries]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemGroup]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemCat]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemColor]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemSize]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemStatus]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemPopoint]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemMaxOnHand]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ApCode]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemGold]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemOnHand]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ItemWh]}</td> */}
                                        
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="14">
                                        <center>
                                            <h5>ไม่พบข้อมูล</h5>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                                <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`paginate_button page-item next ${currentPage === Math.ceil(dataMasterList.length / itemsPerPage) ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTableInitialTbSetItem;