import React from 'react';
// import { formatCurrency } from "../../../utils/SamuiUtils";
// import { formatDate } from "../../../utils/DateUtils";

const TeasuryTable = ({ dataList }) => {
    return (
        <div
            className="table-responsive"
            style={{
                maxHeight: window.location.pathname === '/picking-list' ? '350px' : '600px',
                overflowY: 'auto',
            }}
        >
            <table id="basic-datatables" className="table table-striped table-hover" style={{ minWidth: '100%' }}>
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center" style={{ width: '3%' }}>ลำดับ</th>
                        <th
                            hidden={window.location.pathname === '/bill-of-lading'
                                || window.location.pathname === '/picking-list' ? false : true}
                            className="text-center" style={{ width: '12%' }}
                        >
                            เลขเอกสาร
                        </th>
                        <th className="text-center" style={{ width: '9%' }}>รหัสสินค้า</th>
                        <th
                            className="text-center"
                            style={window.location.pathname === '/bill-of-lading'
                                || window.location.pathname === '/picking-list' ? { width: '28%' } : { width: '31%' }}>
                            ชื่อสินค้า
                        </th>
                        <th className="text-center" style={{ width: '6%' }}>จำนวน</th>
                        <th className="text-center" style={{ width: '8%' }}>หน่วย</th>
                        {/* <th className="text-center" style={{ width: '8%' }}>ราคา/หน่วย</th>
                        <th className="text-center" style={{ width: '4%' }}>Todo</th> */}
                        {/* <th className="text-center" style={{ width: '10%' }}>จากคลัง</th> */}
                        {/* <th className="text-center" style={{ width: '8%' }}>วันที่จัดส่ง</th>
                        <th className="text-center" style={{ width: '8%' }}>ไปยัง
                            {window.location.pathname === '/bill-of-lading'
                                || window.location.pathname === '/order-product'
                                || window.location.pathname === '/picking-list' ? 'รถ' : 'คลัง'}
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {dataList.length > 0 ? (
                        dataList.map((item, index) => (
                            <tr key={item.DT_Id || index + 1}>
                                <td className="text-center">{index + 1}</td>
                                <td
                                    hidden={window.location.pathname === '/bill-of-lading'
                                        || window.location.pathname === '/picking-list' ? false : true}
                                    className="text-center">
                                    {item.Ref_DocNo || '-'}
                                </td>
                                <td className="text-center">{item.Item_Code}</td>
                                <td className="text-left">{item.Item_Name}</td>
                                <td className="text-center">{item.Item_Qty}</td>
                                <td className="text-center">{item.Item_Unit}</td>
                                {/* <td className="text-center">{formatCurrency(item.Item_Price_Unit)}</td>
                                <td className="text-center">{item.Doc_Type}</td> */}
                                {/* <td className="text-center">{item.F_WH_Name}</td> */}
                                {/* <td className="text-center">{formatDate(item.WHDoc_TransDate)}</td>
                                <td className="text-center">{item.T_WH_Name}</td> */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="14">
                                <center>
                                    <h5>ไม่พบข้อมูล</h5>
                                </center>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TeasuryTable;