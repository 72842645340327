import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// React DateTime
import Datetime from 'react-datetime';
import moment from 'moment';

// Components
import Breadcrumbs from "../../Breadcrumbs";
import ArModal from '../../Modal/ArModal';
// import PkModal from '../../Modal/PkModal';
// import ItemWhTable from '../../Content/ItemWhTable';
import ItemTable from '../../Content/ItemTable';
import FormAction from '../../Actions/FormAction';

// Model
import { whDocMasterModel } from '../../../../model/Warehouse/WHDocMasterModel';
import { whDocDetailModel } from '../../../../model/Warehouse/WHDocDetailModel';

import {
  getData,
  getDataWithComp,
  getViewItem,
  formatCurrency,
  parseCurrency,
  getAlert,
  getMaxOdNo,
  deleteDetail
} from "../../../../utils/SamuiUtils";

// import {
//   manageWhItemData
// } from '../../../../utils/WarehouseUtils';

import {
  formatDateTime,
  formatThaiDateUi,
  formatDateOnChange,
  formatStringDateToDate,
  formatThaiDateUiToDate,
  setCreateDateTime
} from "../../../../utils/DateUtils";

const Form = ({ callInitialize, mode, name, whDocNo, pickingList }) => {
  const [formMasterList, setFormMasterList] = useState(whDocMasterModel());
  const [formDetailList, setFormDetailList] = useState([]);
  const [itemDataList, setItemDataList] = useState([]);
  // const [whFromDataList, setWhFromDataList] = useState([]);
  // const [whToDataList, setWhToDataList] = useState([]);
  const [whDataList, setWhDataList] = useState([]);
  const [arDataList, setArDataList] = useState([]);
  // const [pkDataList, setPkDataList] = useState([]);

  // การเลือกคลังจากหน้าจอ
  const [selectedWarehouseFrom, setSelectedWarehouseFrom] = useState(null);
  const [selectedWarehouseTo, setSelectedWarehouseTo] = useState(null);

  // เก็บค่า Disabled (ตรวจสอบว่าใบออเดอร์นี้มีการสร้างใบเบิกหรือยัง)
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    // const whFromDataList = await getDataWithComp('Tb_Set_WH', `AND Flag_Vansale = 'N' ORDER BY WH_Code ASC`);
    // if (whFromDataList && whFromDataList.length > 0) {
    //   setWhFromDataList(whFromDataList);
    // }

    // const whToDataList = await getDataWithComp('Tb_Set_WH', `AND Flag_Vansale = 'Y' ORDER BY WH_Code ASC`);
    // if (whToDataList && whToDataList.length > 0) {
    //   setWhToDataList(whToDataList);
    // }

    const whDataList = await getDataWithComp('Tb_Set_WH', 'ORDER BY WH_Code ASC');
    if (whDataList && whDataList.length > 0) {
      setWhDataList(whDataList);
    }

    const itemDataList = await getViewItem();
    if (itemDataList && itemDataList.length > 0) {
      setItemDataList(itemDataList);
    }

    const arDataList = await getDataWithComp('Tb_Set_AR', 'ORDER BY AR_Code ASC');
    if (arDataList && arDataList.length > 0) {
      setArDataList(arDataList);
    }

    // const pkDataList = await getDataWithComp('API_0801_PKList_H', 'ORDER BY Doc_No DESC');
    // if (pkDataList && pkDataList.length > 0) {
    //   setPkDataList(pkDataList);
    // }

    // ถ้ากดมาจาก Picking List
    if (mode === 'I' && window.location.pathname === '/picking-list') {
      // setFormMasterList({
      //   ...formMasterList,
      //   refDocId: pickingList.docId,
      //   refDoc: pickingList.docNo,
      //   refDocDate: pickingList.docDate
      // });

      const getWarehouseByCarNo = await getDataWithComp('Tb_Set_WH', `AND WH_Code = '${pickingList.pkCarNo}'`);
      if (getWarehouseByCarNo && getWarehouseByCarNo.length > 0) {
        setSelectedWarehouseTo(getWarehouseByCarNo[0].WH_Id);
      }

      // ถ้าเป็นใบขาย ให้แสดงไอเทมตามที่ลูกค้ามี
      // const pkListDetails = await getData('PKList_D', `Doc_Id = '${pickingList.docId}'`);

      // for (const result of pkListDetails) {
      //   if (result.SO_Id != null) {
      //     let findSoDetail = await getData('SO_D', `Doc_ID = '${result.SO_Id}'`);

      //     for (const detail of findSoDetail) {

      //       let itemOnHand = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(detail.WH_Id)} AND Item_Id = ${Number(detail.Item_Id)}`);

      //       setFormDetailList(prevFormDetailList => {
      //         // ตรวจสอบว่ามีไอเทมนี้ใน formDetailList หรือไม่
      //         const existingItemIndex = prevFormDetailList.findIndex(item => item.itemId === detail.Item_Id);

      //         if (existingItemIndex > -1) {
      //           // ถ้าเจอไอเทมที่ซ้ำกัน ให้เพิ่มจำนวน qty เข้าไปในรายการที่มีอยู่
      //           const updatedItem = {
      //             ...prevFormDetailList[existingItemIndex],
      //             itemOrder: prevFormDetailList[existingItemIndex].itemOrder + detail.Item_Qty,
      //             itemBalance: prevFormDetailList[existingItemIndex].itemBalance + detail.Item_Qty
      //           };

      //           // อัปเดตรายการเดิมใน formDetailList
      //           return [
      //             ...prevFormDetailList.slice(0, existingItemIndex),
      //             updatedItem,
      //             ...prevFormDetailList.slice(existingItemIndex + 1)
      //           ];
      //         } else {
      //           // ถ้าไม่เจอไอเทม ให้สร้างรายการใหม่
      //           let newRow = whDocDetailModel(prevFormDetailList.length + 1);

      //           return [
      //             ...prevFormDetailList,
      //             {
      //               ...newRow,
      //               line: null,
      //               itemId: detail.Item_Id,
      //               itemCode: detail.Item_Code,
      //               itemName: detail.Item_Name,
      //               itemUnit: detail.Item_Unit,
      //               itemOnHand: itemOnHand[0].Item_Onhand,
      //               itemOrder: detail.Item_Qty || 0,
      //               itemBalance: itemOnHand[0].Item_Onhand,
      //               itemPriceUnit: detail.Item_Price_Unit,
      //               itemTotal: detail.Item_Total,
      //               docType: null
      //             }
      //           ];
      //         }
      //       });
      //     }
      //   }
      // }
    }

    // สำหรับ View เข้ามาเพื่อแก้ไขข้อมูล
    if (mode === 'U') {
      await getModelByNo(arDataList);
    }
  };

  const getModelByNo = async (arDataList) => {
    try {
      const fromDatabase = await getDataWithComp('WHDoc_H', `AND WHDoc_No = '${whDocNo}'`);

      if (!fromDatabase) {
        throw new Error("ไม่พบข้อมูลเอกสาร");
      }

      // ตรวจสอบว่ามีการสร้างใบเบิกหรือยัง
      let checkByWhDocId = await getDataWithComp('API_1002_WHDOC_D', `AND Ref_DocID = '${fromDatabase[0].WHDoc_Id}'`);
      if (checkByWhDocId && checkByWhDocId.length > 0) {
        setIsDisabled(true);
      }

      // ค้นหาข้อมูลผู้ขายด้วย AR_ID
      const fromViewAr = arDataList.find(ap => ap.AR_Id === fromDatabase[0].WHDoc_Cust_Id);

      if (!fromViewAr) {
        throw new Error("ไม่พบข้อมูลลูกค้า");
      }

      // ฟังก์ชันดึงข้อมูล itemOnHand
      // const getOnHand = async (whId, itemId) => {
      //   const itemOnHand = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(whId)} AND Item_Id = ${Number(itemId)}`);
      //   return itemOnHand[0].Item_Onhand;
      // };

      // ฟังก์ชันสร้างแถวใหม่
      // const createNewRow = (index, itemSelected, itemOnHand) => {
      //   return {
      //     ...whDocDetailModel(index + 1),
      //     dtId: itemSelected.DT_Id,
      //     whDocId: itemSelected.WHDoc_Id || null,
      //     line: itemSelected.Line || null,
      //     itemId: itemSelected.Item_Id || null,
      //     itemCode: itemSelected.Item_Code || null,
      //     itemName: itemSelected.Item_Name || null,
      //     itemOnHand: itemOnHand || null,
      //     itemQty: itemSelected.Item_Qty || null,
      //     itemIncrease: null,
      //     itemDecrease: itemSelected.Item_Qty,
      //     itemOrder: null,
      //     itemBalance: Number(itemOnHand) - Number(itemSelected.Item_Qty),
      //     itemUnit: itemSelected.Item_Unit || null,
      //     itemPriceUnit: itemSelected.Item_Price_Unit || null,
      //     itemDiscount: null,
      //     itemDisType: null,
      //     itemTotal: itemSelected.Item_Total || null,
      //     docType: itemSelected.Doc_Type || null,
      //     fWhId: itemSelected.F_WH_Id || null,
      //     fZoneId: itemSelected.F_Zone_Id || null,
      //     fLtId: itemSelected.F_LT_Id || null,
      //     tWhId: itemSelected.T_WH_Id || null,
      //     tZoneId: itemSelected.T_Zone_Id || null,
      //     tLtId: itemSelected.T_LT_Id || null,
      //     soId: itemSelected.SO_Id || null
      //   };
      // };
      const createNewRow = (index, itemSelected) => {
        return {
          ...whDocDetailModel(index + 1),
          dtId: itemSelected.DT_Id,
          whDocId: itemSelected.WHDoc_Id || null,
          line: itemSelected.Line || null,
          itemId: itemSelected.Item_Id || null,
          itemCode: itemSelected.Item_Code || null,
          itemName: itemSelected.Item_Name || null,
          itemOnHand: null,
          itemQty: itemSelected.Item_Qty || null,
          itemIncrease: null,
          itemDecrease: itemSelected.Item_Qty,
          itemOrder: null,
          itemBalance: null,
          itemUnit: itemSelected.Item_Unit || null,
          itemPriceUnit: itemSelected.Item_Price_Unit || null,
          itemDiscount: null,
          itemDisType: null,
          itemTotal: itemSelected.Item_Total || null,
          docType: itemSelected.Doc_Type || null,
          refDocId: itemSelected.Ref_DocID || null,
        };
      };

      // ค้นหาข้อมูลของ Detail ด้วย WHDoc_Id
      const fromDetail = await getData('WHDoc_D', `WHDoc_Id = ${fromDatabase[0].WHDoc_Id} ORDER BY Line ASC`);

      if (fromDetail.length > 0) {
        // สร้าง Promises สำหรับ itemOnHand
        // const itemOnHandPromises = fromDetail.map(item => getOnHand(item.F_WH_Id, item.Item_Id));

        // รอ Promises
        // const itemOnHandResults = await Promise.all(itemOnHandPromises);

        // สร้าง newFormDetails โดยใช้ itemOnHandResults
        // const newFormDetails = fromDetail.map((item, index) => createNewRow(formDetailList.length + index, item, itemOnHandResults[index]));
        const newFormDetails = fromDetail.map((item, index) => createNewRow(formDetailList.length + index, item));

        // await handleChangeWarehouse(String(newFormDetails[0].fWhId), 'FROM');
        // await handleChangeWarehouse(String(newFormDetails[0].tWhId), 'TO');
        setFormDetailList(newFormDetails);

        const data = fromDatabase[0];

        setFormMasterList({
          whDocId: data.WHDoc_Id,
          whDocNo: data.WHDoc_No,
          whDocType: data.WHDoc_Type,
          whDocCustId: data.WHDoc_Cust_Id,
          whDocCustCode: fromViewAr.AR_Code,
          whDocCustName: fromViewAr.AR_Name,
          whDocDate: data.WHDoc_Date,
          whDocTransDate: data.WHDoc_TransDate,
          whDocCreatedDate: setCreateDateTime(data.WHDoc_CreatedDate),
          whDocCreatedBy: data.WHDoc_CreatedBy,
          whDocStatus: data.WHDoc_Status,
          whDocSeq: data.WHDoc_SEQ,
          whDocRemark: data.WHDoc_Remark,
          whDocCompId: data.Comp_Id,
          whDocShowFront: data.WHDoc_ShowFront,
          refDocId: data.Ref_DocID,
          refDoc: null,
          refDocDate: null,
          fWhId: data.F_WH_Id,
          fZoneId: data.F_Zone_Id,
          fLtId: data.F_LT_Id,
          tWhId: data.T_WH_Id,
          tZoneId: data.T_Zone_Id,
          tLtId: data.T_LT_Id,

          // ใช้สำหรับแสดงเฉยๆ
          arAdd1: fromViewAr.AR_Add1,
          arAdd2: fromViewAr.AR_Add2,
          arAdd3: fromViewAr.AR_Add3,
          arProvince: fromViewAr.AR_Province,
          arZipcode: fromViewAr.AR_Zipcode,
          arTaxNo: fromViewAr.AR_TaxNo,
        });

      } else {
        getAlert('FAILED', `ไม่พบข้อมูลที่ตรงกับเลขที่เอกสาร ${fromDatabase[0].WHDoc_No} กรุณาตรวจสอบและลองอีกครั้ง`);
      }
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  const handleChangeMaster = (e) => {
    const { name, value } = e.target;
    // อัปเดตค่าใน formMasterList
    setFormMasterList((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeDateMaster = (value, name) => {
    // ตรวจสอบว่า value เป็น moment object หรือไม่
    const newValue = value && value instanceof moment ? value.format('YYYY-MM-DD') : value;
    // อัปเดตค่าใน formMasterList
    setFormMasterList((prev) => ({
      ...prev,
      [name]: formatDateOnChange(newValue),
    }));
  };

  // const handleChangeWarehouse = async (value, type) => {
  //   if (type === 'FROM') {

  //     // เคลียค่าตอนกดย้ายคลัง
  //     setFormDetailList([]);

  //     const itemDataList = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Id = ${Number(value)} ORDER BY Item_Code ASC`);
  //     if (itemDataList && itemDataList.length > 0) {
  //       setItemDataList(itemDataList);
  //     }
  //     setSelectedWarehouseFrom(value);
  //   } else {
  //     setSelectedWarehouseTo(value);
  //   }
  // };

  // const handleQuantityChange = (index, type, value, onHand) => {
  //   // ดักค่าเพื่อให้ส่งมาอย่างถูกต้อง (จริงๆแล้วแทบไม่ได้ใช้)
  //   if (type !== 'decrease') {
  //     getAlert("FAILED", "พบข้อผิดพลาดในการดำเนินการ กรุณารีเฟชรแล้วลองใหม่");
  //   }

  //   // ตรวจสอบว่าค่าที่กรอกเข้ามาเป็นตัวเลขหรือตัวเลขที่มีจุดทศนิยม
  //   if (!/^\d*\.?\d*$/.test(value)) {
  //     //getAlert("FAILED", "กรุณากรอกเฉพาะตัวเลขเท่านั้น");
  //     return;
  //   }

  //   const updatedList = [...formDetailList];

  //   // ดักไม่ให้ปรับปรุงเกินกว่าสินค้าคงเหลือ เฉพาะตอนปรับลดสินค้า
  //   if (value > onHand) {
  //     getAlert("FAILED", "กรุณาระบุจำนวนไม่เกินคงเหลือ");
  //     updatedList[index].itemDecrease = null;
  //     updatedList[index].docType = null;
  //     updatedList[index].itemBalance = Number(onHand);
  //     setFormDetailList(updatedList);
  //     return;
  //   }

  //   updatedList[index].docType = "TO";
  //   updatedList[index].itemDecrease = value;
  //   updatedList[index].itemBalance = Number(onHand) - Number(value);
  //   updatedList[index].itemQty = Number(value);

  //   setFormDetailList(updatedList);
  // };

  const handleChangeDetail = (index, field, value) => {
    // ตรวจสอบว่าค่าที่กรอกเข้ามาเป็นตัวเลขหรือตัวเลขที่มีจุดทศนิยม
    if (!/^\d*\.?\d*$/.test(value)) {
      //getAlert("FAILED", "กรุณากรอกเฉพาะตัวเลขเท่านั้น");
      return;
    }

    const updatedList = [...formDetailList];
    updatedList[index][field] = value;

    const itemQty = Number(updatedList[index].itemQty) || 0;
    const itemPriceUnit = Number(parseCurrency(updatedList[index].itemPriceUnit)) || 0;
    const itemDiscount = Number(parseCurrency(updatedList[index].itemDiscount)) || 0;
    const itemDisType = String(updatedList[index].itemDisType);

    let itemTotal = itemQty * itemPriceUnit;

    if (itemDisType === '2') {
      itemTotal -= (itemDiscount / 100) * itemTotal; // ลดตามเปอร์เซ็นต์
    } else {
      itemTotal -= itemDiscount; // ลดตามจำนวนเงิน
    }

    updatedList[index].itemTotal = itemTotal;
    setFormDetailList(updatedList);
  };

  const handleFocus = (index, field) => {
    const updatedList = [...formDetailList];
    updatedList[index][field] = Number(updatedList[index][field].replace(/,/g, '')) || 0;
    setFormDetailList(updatedList);
  };

  const handleBlur = (index, field, value) => {
    const numericValue = Number(value.replace(/,/g, '')) || 0;
    const formattedValue = formatCurrency(numericValue);

    const updatedList = [...formDetailList];
    updatedList[index][field] = formattedValue;
    setFormDetailList(updatedList);
  };

  // SET AR
  const [showArModal, setShowArModal] = useState(false);
  const handleArShow = () => setShowArModal(true);
  const handleArClose = () => setShowArModal(false);
  const onRowSelectAr = (arSelected) => {
    try {
      setFormMasterList({
        ...formMasterList,
        whDocCustId: arSelected.AR_Id,
        whDocCustCode: arSelected.AR_Code,
        whDocCustName: arSelected.AR_Name,
        arAdd1: arSelected.AR_Add1,
        arAdd2: arSelected.AR_Add2,
        arAdd3: arSelected.AR_Add3,
        arProvince: arSelected.AR_Province,
        arZipcode: arSelected.AR_Zipcode,
        arTaxNo: arSelected.AR_TaxNo,
      });

      handleArClose(); // ปิด modal หลังจากเลือก
    } catch (error) {
      getAlert("FAILED", error);
    }
  };

  // SET ITEM
  const [showItemModal, setShowItemModal] = useState(false);
  const handleItemShow = () => setShowItemModal(true);
  const handleItemClose = () => setShowItemModal(false);
  const onRowSelectItem = (itemSelected) => {
    try {
      // ตรวจสอบว่า Item_Id ซ้ำกันหรือไม่
      const isDuplicate = formDetailList.some((item) => item.itemId === itemSelected.Item_Id);

      if (isDuplicate) {
        return getAlert("FAILED", `${itemSelected.Item_Name} ถูกเลือกไปแล้วในรายการ กรุณาเลือกสินค้าอื่น`);
      }

      const newRow = whDocDetailModel(formDetailList.length + 1);

      setFormDetailList([
        ...formDetailList,
        {
          ...newRow,
          line: null,
          itemId: itemSelected.Item_Id,
          itemCode: itemSelected.Item_Code,
          itemName: itemSelected.Item_Name,
          itemUnit: itemSelected.Item_Unit_ST,
          itemOnHand: itemSelected.Item_Onhand || 0,
          itemBalance: itemSelected.Item_Onhand || 0,
          itemPriceUnit: 0,
          itemTotal: 0,
          docType: "TO"
        }
      ]);

      handleItemClose(); // ปิด modal หลังจากเลือก
    } catch (error) {
      getAlert("FAILED", error);
    }
  };
  const handleRemoveRow = (index) => {
    const newList = formDetailList.filter((_, i) => i !== index);
    setFormDetailList(newList);
  };

  // SET PK
  // const [showPkModal, setShowPkModal] = useState(false);
  // const handlePkShow = () => setShowPkModal(true);
  // const handlePkClose = () => setShowPkModal(false);
  // const onRowSelectPk = async (pkSelected) => {
  //   try {
  //     // เคลียร์ค่าใน formMasterList และ formDetailList
  //     setFormMasterList({});
  //     setFormDetailList([]);

  //     // const [fromViewDetail] = await Promise.all([
  //     //   getDataWithComp("API_0802_PKList_D", "ORDER BY DL_Queue ASC")
  //     // ]);

  //     setFormMasterList({
  //       ...formMasterList,
  //       refDocId: pkSelected.Doc_Id,
  //       refDoc: pkSelected.Doc_No,
  //       refDocDate: formatThaiDateUi(pkSelected.Doc_Date)
  //     });

  //     // const newRow = whDocDetailModel(formDetailList.length + 1);

  //     // setFormDetailList([
  //     //   ...formDetailList,
  //     //   {
  //     //     ...newRow,
  //     //     line: null,
  //     //     itemId: itemSelected.Item_Id,
  //     //     itemCode: itemSelected.Item_Code,
  //     //     itemName: itemSelected.Item_Name,
  //     //     itemUnit: itemSelected.Item_Unit_ST,
  //     //     itemOnHand: itemSelected.Item_Onhand || 0,
  //     //     itemBalance: itemSelected.Item_Onhand || 0,
  //     //     itemPriceUnit: 0,
  //     //     itemTotal: 0,
  //     //     docType: null
  //     //   }
  //     // ]);

  //     handlePkClose(); // ปิด modal หลังจากเลือก
  //   } catch (error) {
  //     getAlert("FAILED", error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const findMaxOdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '4' ORDER BY WHDoc_No DESC`);
      const maxOd = getMaxOdNo(findMaxOdNo);
      let newMaxOd = maxOd;

      // if (formMasterList.refDocId === null) {
      //   getAlert("FAILED", "กรุณาเลือกอ้างอิงเอกสาร");
      //   return;
      // }
      // if (selectedWarehouseFrom === null) {
      //   getAlert("FAILED", "กรุณาเลือกจากคลัง");
      //   return;
      // }
      // if (selectedWarehouseTo === null) {
      //   getAlert("FAILED", "กรุณาเลือกไปคลัง");
      //   return;
      // }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มรายละเอียดสินค้าที่จะออเดอร์");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // ตรวจสอบค่าภายใน formDetailList
      for (const item of formDetailList) {
        if (!item.itemQty || parseInt(item.itemQty) === 0) {
          getAlert("FAILED", `กรุณากรอกจำนวนออเดอร์ของสินค้า ${item.itemName}`);
          return; // หยุดการทำงานหากจำนวนของสินค้าเป็น 0 หรือไม่มีค่า
        }

        // ถ้าเป็นการเปิด Modal จาก PickingList
        // if (window.location.pathname === '/picking-list') {
        //   if (parseInt(item.itemBalance) !== 0) {
        //     const isConfirmed = await new Promise((resolve) => {
        //       getAlert("C_DIALOG", `จำนวนสินค้า ${item.itemName} ไม่ครบตามคำสั่งซื้อ ท่านยืนยันที่จะบันทึกข้อมูลนี้หรือไม่?`, resolve);
        //     });

        //     if (!isConfirmed) {
        //       return; // หยุดการทำงานเมื่อผู้ใช้กดยกเลิก
        //     }
        //   }
        // }
      }

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        wh_doc_no: newMaxOd,
        wh_doc_type: parseInt("4", 10),
        wh_doc_cust_id: parseInt(formMasterList.whDocCustId, 10),
        wh_doc_date: formatStringDateToDate(formMasterList.whDocDate),
        wh_doc_trans_date: formatStringDateToDate(formMasterList.whDocTransDate),
        wh_doc_created_date: formatThaiDateUiToDate(formMasterList.whDocCreatedDate),
        wh_doc_created_by: formMasterList.whDocCreatedBy,
        wh_doc_status: parseInt("1", 10),
        wh_doc_seq: formatDateTime(new Date()),
        wh_doc_remark: formMasterList.whDocRemark,
        wh_doc_comp_id: formMasterList.whDocCompId,
        wh_doc_show_front: null,
        ref_doc_id: formMasterList.refDocId,
        f_wh_id: selectedWarehouseFrom,
        f_zone_id: formMasterList.fZoneId,
        f_lt_id: formMasterList.fLtId,
        t_wh_id: selectedWarehouseTo,
        t_zone_id: formMasterList.fZoneId,
        t_lt_id: formMasterList.fLtId,
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        const getWhDocIdResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-by-wh-doc-no`, {
          table: 'WHDoc_H',
          wh_doc_no: formMasterData.wh_doc_no
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (getWhDocIdResponse && getWhDocIdResponse.data.length > 0) {
          const whDocId = parseInt(getWhDocIdResponse.data[0].WHDoc_Id, 10);
          let line = 1;

          const detailPromises = formDetailList.map(async (item) => {
            const formDetailData = {
              wh_doc_id: whDocId,
              line: line,
              item_id: item.itemId,
              item_code: item.itemCode,
              item_name: item.itemName,
              item_qty: item.itemQty,
              item_unit: item.itemUnit,
              item_price_unit: item.itemPriceUnit,
              item_total: item.itemTotal,
              doc_type: item.docType,
              ref_doc_id: item.refDocId
            };
            line++;

            // // ย้ายออก
            // await manageWhItemData(
            //   item.itemId, // Item ไอดีของสิ่งนั้นๆ
            //   item.itemCode, // Item โค้ดของสิ่งนั้นๆ
            //   item.itemName, // ชื่อ Item นั้นๆ
            //   selectedWarehouseFrom, // WarehouseID ของไอเทม
            //   "TO", // ประเภท เช่น IN/OUT/AI/AO/TI/TO
            //   Number(item.itemQty), // ค่าจำนวนที่กรอกจากหน้าจอ
            //   whDocId, // ไอดีเอกสาร
            //   newMaxOd, // เลขเอกสาร
            //   formMasterList.refDoc, // เลขเอกสารอ้างอิง
            //   formMasterList.whDocRemark // หมายเหตุ
            // );

            // // ย้ายเข้า
            // await manageWhItemData(
            //   item.itemId, // Item ไอดีของสิ่งนั้นๆ
            //   item.itemCode, // Item โค้ดของสิ่งนั้นๆ
            //   item.itemName, // ชื่อ Item นั้นๆ
            //   selectedWarehouseTo, // WarehouseID ของไอเทม
            //   "TI", // ประเภท เช่น IN/OUT/AI/AO/TI/TO
            //   Number(item.itemQty), // ค่าจำนวนที่กรอกจากหน้าจอ
            //   whDocId, // ไอดีเอกสาร
            //   newMaxOd, // เลขเอกสาร
            //   formMasterList.refDoc, // เลขเอกสารอ้างอิง
            //   formMasterList.whDocRemark // หมายเหตุ
            // );

            return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-d`, formDetailData, {
              headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });
          });

          await Promise.all(detailPromises);
        }

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      // if (formMasterList.refDocId === null) {
      //   getAlert("FAILED", "กรุณาเลือกอ้างอิงเอกสาร");
      //   return;
      // }
      // if (selectedWarehouseFrom === null) {
      //   getAlert("FAILED", "กรุณาเลือกจากคลัง");
      //   return;
      // }
      // if (selectedWarehouseTo === null) {
      //   getAlert("FAILED", "กรุณาเลือกไปคลัง");
      //   return;
      // }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มรายละเอียดสินค้าที่จะเบิก");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // ตรวจสอบค่าภายใน formDetailList
      for (const item of formDetailList) {
        if (!item.itemQty || parseInt(item.itemQty) === 0) {
          getAlert("FAILED", `กรุณากรอกจำนวนเบิกของสินค้า ${item.itemName}`);
          return; // หยุดการทำงานหากจำนวนของสินค้าเป็น 0 หรือไม่มีค่า
        }
      }

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        wh_doc_no: formMasterList.whDocNo,
        wh_doc_type: formMasterList.whDocType,
        wh_doc_cust_id: parseInt(formMasterList.whDocCustId, 10),
        wh_doc_date: formatStringDateToDate(formMasterList.whDocDate),
        wh_doc_trans_date: formatStringDateToDate(formMasterList.whDocTransDate),
        wh_doc_created_date: formatThaiDateUiToDate(formMasterList.whDocCreatedDate),
        wh_doc_created_by: formMasterList.whDocCreatedBy,
        wh_doc_status: formMasterList.whDocStatus,
        wh_doc_seq: formMasterList.whDocSeq,
        wh_doc_remark: formMasterList.whDocRemark,
        wh_doc_comp_id: formMasterList.whDocCompId,
        wh_doc_show_front: formMasterList.whDocShowFront,
        ref_doc_id: formMasterList.refDocId,
        f_wh_id: selectedWarehouseFrom,
        f_zone_id: formMasterList.fZoneId,
        f_lt_id: formMasterList.fLtId,
        t_wh_id: selectedWarehouseTo,
        t_zone_id: formMasterList.fZoneId,
        t_lt_id: formMasterList.fLtId,
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-wh-doc-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {

        // ลบข้อมูลเดิมก่อนจะเริ่มการบันทึกใหม่
        await deleteDetail('WHDoc_D', `WHERE WHDoc_Id = ${formMasterList.whDocId}`);

        const whDocId = parseInt(formMasterList.whDocId, 10);
        let line = 1;

        const detailPromises = formDetailList.map(async (item) => {
          const formDetailData = {
            wh_doc_id: whDocId,
            line: line,
            item_id: item.itemId,
            item_code: item.itemCode,
            item_name: item.itemName,
            item_qty: item.itemQty,
            item_unit: item.itemUnit,
            item_price_unit: item.itemPriceUnit,
            item_total: item.itemTotal,
            doc_type: item.docType,
            ref_doc_id: item.refDocId
          };
          line++;

          return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-wh-doc-d`, formDetailData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });
        });

        await Promise.all(detailPromises);

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleCancel = async () => {
    console.debug("Begin handleCancel");
  };

  return <>
    <div hidden={window.location.pathname === '/picking-list'}>
      <Breadcrumbs page={whDocNo} items={[
        { name: 'ขายสินค้า', url: '/sellproducts' },
        { name: name, url: '/order-product' },
        { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' }
      ]} />
    </div>

    <div className="body">
      <div class="container-fluid my-4">
        <h5 className="">ใบออเดอร์สินค้า</h5>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่เอกสาร : </label>
              <Datetime
                className="input-spacing-input-date"
                name="whDocDate"
                value={formMasterList.whDocDate || null}
                onChange={(date) => handleChangeDateMaster(date, 'whDocDate')}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ readOnly: true, disabled: mode === 'U' }}
              />
            </div>
          </div>
          <div className="col-4 text-left">
            <div className="d-flex align-items-center">
              <label>ลูกค้า</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input-spacing"
                  name="apCode"
                  value={
                    (formMasterList.whDocCustCode || '')
                    + " " +
                    (formMasterList.whDocCustName || '')
                  }
                  onChange={handleChangeMaster}
                  disabled={true}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={handleArShow}
                  disabled={mode === 'U' ? true : false}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <ArModal
              showArModal={showArModal}
              handleArClose={handleArClose}
              arDataList={arDataList}
              onRowSelectAr={onRowSelectAr}
            />
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label>สร้างโดย : </label>
              <input
                type="text"
                className="form-control input-spacing"
                value={formMasterList.whDocCreatedBy}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่จัดส่ง : </label>
              <Datetime
                className="input-spacing-input-date"
                name="whDocTransDate"
                value={formMasterList.whDocTransDate || null}
                onChange={(date) => handleChangeDateMaster(date, 'whDocTransDate')}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ readOnly: true, disabled: isDisabled }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <label>ที่อยู่</label>
              <input
                type="text"
                className="form-control input-spacing"
                name="apAdd1"
                value={formMasterList.arAdd1 || ''}
                onChange={handleChangeMaster}
                disabled={true} />
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex">
              <label className="">วันที่สร้าง : </label>
              <input
                type="text"
                className="form-control input-spacing"
                value={formMasterList.whDocCreatedDate}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>อ้างอิงเอกสาร : </label>
              <input
                type="text"
                className="form-control input-spacing"
                name="refDoc"
                value={formMasterList.refDoc || ''}
                onChange={handleChangeMaster}
                disabled={true}
              />
              {/* <div className="input-group">
                <input
                  type="text"
                  className="form-control input-spacing"
                  name="refDoc"
                  value={formMasterList.refDoc || ''}
                  onChange={handleChangeMaster}
                  disabled={true}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={handlePkShow}
                  disabled={true}>
                  <i className="fas fa-search" />
                </button>
                <PkModal
                  showPkModal={showPkModal}
                  handlePkClose={handlePkClose}
                  pkDataList={pkDataList}
                  onRowSelectPk={onRowSelectPk}
                />
              </div> */}
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <label></label>
              <input
                type="text"
                className="form-control input-spacing"
                value={
                  (formMasterList.arAdd2 || '')
                  + " " +
                  (formMasterList.arAdd3 || '')
                }
                disabled={true} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>วันที่เอกสารอ้างอิง : </label>
              <input
                //type="date"
                type="text"
                className="form-control input-spacing"
                name="refDocDate"
                value={formMasterList.refDocDate || ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <label></label>
              <input
                type="text"
                className="form-control input-spacing"
                value={
                  (formMasterList.arProvince || '')
                  + " " +
                  (formMasterList.arZipcode || '')
                }
                disabled={true} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="d-flex">
              <label>หมายเหตุ : </label>
              <input
                type="text"
                className="form-control input-spacing"
                name="whDocRemark"
                value={formMasterList.whDocRemark || ''}
                onChange={handleChangeMaster}
                disabled={isDisabled} />
            </div>
            {/* <div className="d-flex">
              <label>จากคลัง : </label>
              <select
                class="form-select"
                value={selectedWarehouseFrom}
                onChange={(e) => handleChangeWarehouse(e.target.value, 'FROM')}>
                <option value={null}>
                  กรุณาระบุคลัง
                </option>
                {whFromDataList.map((warehouse) => (
                  <option
                    key={warehouse.WH_Id}
                    value={warehouse.WH_Id}
                  >
                    {warehouse.WH_Name}
                  </option>
                ))}
              </select>
            </div> */}
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <label></label>
              <input
                type="text"
                className="form-control input-spacing"
                value={formMasterList.arTaxNo || ''}
                disabled={true} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            {/* <div className="d-flex">
              <label>ไปคลัง :</label>
              <select
                class="form-select"
                value={selectedWarehouseTo}
                onChange={(e) => handleChangeWarehouse(e.target.value, 'TO')}>
                <option value={null}>
                  กรุณาระบุคลัง
                </option>
                {whToDataList.map((warehouse) => (
                  <option
                    key={warehouse.WH_Id}
                    value={warehouse.WH_Id}
                  >
                    {warehouse.WH_Code} ({warehouse.WH_Name})
                  </option>
                ))}
              </select>
            </div> */}
          </div>
          <div className="col-4">
            {/* <div className="d-flex">
              <label>หมายเหตุ : </label>
              <input
                type="text"
                className="form-control input-spacing"
                name="whDocRemark"
                value={formMasterList.whDocRemark || ''}
                onChange={handleChangeMaster}
                disabled={false} />
            </div> */}
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div className="container-fluid my-3">
      <div className="row">
        <div className="col-12">
          {/* <ItemWhTable
            mode={mode}
            showItemModal={showItemModal}
            handleItemClose={handleItemClose}
            itemDataList={itemDataList}
            onRowSelectItem={onRowSelectItem}
            formDetailList={formDetailList}
            handleItemShow={handleItemShow}
            handleQuantityChange={handleQuantityChange}
            handleRemoveRow={handleRemoveRow}
            formatCurrency={formatCurrency}
            disabled={selectedWarehouseFrom === null}
          /> */}
          <ItemTable
            formDetailList={formDetailList}
            handleChangeDetail={handleChangeDetail}
            handleRemoveRow={handleRemoveRow}
            formatCurrency={formatCurrency}
            showItemModal={showItemModal}
            handleItemClose={handleItemClose}
            itemDataList={itemDataList}
            onRowSelectItem={onRowSelectItem}
            handleItemShow={handleItemShow}
            whDataList={whDataList}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            disabled={isDisabled}
          />
          <FormAction
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            onCancel={handleCancel}
            mode={mode}
            disabled={isDisabled}
            footer={window.location.pathname === '/picking-list' ? false : true}
          />
        </div>
      </div>
    </div>
  </>
};

export default Form;