export const InitialDataDebtor = () => {
    return {
        ar_id: null, // Int
        comp_id: window.localStorage.getItem('company'), // Int
        ar_code: null,
        ar_name: null,
        ar_type: null, // Int
        ar_shortname: null,
        ar_taxno: null,
        ar_add1: null,
        ar_add2: null,
        ar_add3: null,
        ar_province: null,
        ar_zipcode: null,
        ar_contact1: null,
        ar_contact2: null,
        ar_tel: null,
        ar_tel2: null,
        ar_group: null,
        ar_refsalecode: null,
        ar_area: null,
        ar_status: null,
        ar_credit: null, // Int
        ar_vat: null,    // Int
        ar_discount: null,
        ar_branch: null,
        ar_bt_name: null,
        ar_addlat: null,
        ar_addlan: null,
        ar_refsaleName: null,
        ar_credit_term: null,
        ar_credit_limit: null,
    }
}