import React, { useState, useEffect } from 'react';

// Components
import Breadcrumbs from '../../Breadcrumbs';

// Utils
import { getAlert } from '../../../../utils/SamuiUtils';

// Components Date Filter
import DataTableInitialTbSetItem from '../../Content/DataTableInitialTbSetItem';

function Main({ masterList, detailList, name, onPageInsert, onRowSelected }) {

  const [dataMasterList, setDataMasterList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    initialize();
  }, [masterList, detailList]);

  useEffect(() => {
    filterItems();
  }, [searchTerm, dataMasterList]);


  const initialize = async () => {
    try {
      if (masterList && masterList.length > 0) {
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const filterItems = () => {
    if (searchTerm === '') {
      setFilteredItems(dataMasterList);
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const filtered = dataMasterList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(lowercasedSearchTerm)
        )
      );
      setFilteredItems(filtered);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-0">
        <Breadcrumbs page={name} items={[
          { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
          { name: name, url: '/tbsetitem' },
        ]} />
      </div>
      <div className='p-3 rounded-3 my-2 shadow-lg' style={{ backgroundColor: '#ffff' }}>
        <div className='row'>
          <div className="col-3 d-flex">
            <div className="navbar-form nav-search">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="ค้นหาเอกสาร..."
                  className="form-control"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-9 d-flex justify-content-end">
            <button
              onClick={onPageInsert}
              className="btn btn-warning text-white ms-2"
              type="button"
            >
              สร้าง{name} <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <DataTableInitialTbSetItem
          currentItems={currentItems}
          onRowSelected={onRowSelected}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          dataMasterList={filteredItems}
          itemsPerPage={itemsPerPage}
          fieldMappings={{
            ItemId: 'Item_Id',
            ItemBarcode: 'Item_Barcode',
            ItemCode: 'Item_Code',
            ItemName: 'Item_Name',
            ItemNameEn: 'Item_Name_En',
            ItemShortName: 'Item_Short_Name',
            ItemUnitIn: 'Item_Unit_In',
            ItemUnitSt: 'Item_Unit_St',
            ItemUnitOut: 'Item_Unit_Out',
            ItemCost: 'Item_Cost',
            ItemPrice: 'Item_Price',
            ItemPriceA: 'Item_PriceA',
            ItemPriceB: 'Item_PriceB',
            ItemBrand: 'Item_Brand',
            ItemSeries: 'Item_Series',
            ItemGroup: 'Item_Group',
            ItemCat: 'Item_Cat',
            ItemColor: 'Item_Color',
            ItemSize: 'Item_Size',
            ItemStatus: 'Item_Status',
            ItemPopoint: 'Item_Popoint',
            ItemMaxOnHand: 'Item_MaxOnHand',
            ApCode: 'AP_Code',
            ItemGold: 'Item_Gold',
            ItemOnHand: 'Item_OnHand',
            ItemWh: 'Item_WH'
          }}
        />
      </div>
    </>
  );
}

export default Main;