import React from 'react';

// Import หน้าจอ BillOfLading มาแสดงบน Modal
import Form from '../SellProducts/OrderProduct/Form';

const OrderModal = ({ showOrderModal, handleOrderClose, callInitialize, mode, name, whDocNo, pickingList }) => {
    return (
        <>
            <div className={`modal ${showOrderModal ? 'show' : ''}`} style={{ display: showOrderModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="card-title">{whDocNo}</h4>
                            <button type="button" className="close" onClick={handleOrderClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form
                                callInitialize={callInitialize}
                                mode={mode}
                                name={name}
                                whDocNo={whDocNo}
                                pickingList={pickingList}
                            />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger" onClick={handleOrderClose}>ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderModal;