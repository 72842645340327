import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/SellProducts/OrderProduct/Main';
import Form from '../../components/SellProducts/OrderProduct/Form';

// Utils
import {
  getDataWithComp,
  getAlert,
  getMaxOdNo
} from '../../../utils/SamuiUtils';

const OrderProduct = () => {
  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [dataDetailList, setDataDetailList] = useState([]);
  const [maxOdNo, setMaxOdNo] = useState();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime(); // เรียกใช้งาน fetchRealtime เพื่อโหลดข้อมูลเมื่อ component โหลดครั้งแรก
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      const masterList = await getDataWithComp('API_1001_WHDOC_H', `AND WHDoc_Type = '4' ORDER BY WHDoc_No DESC`);
      if (masterList && masterList.length > 0) {
        setDataMasterList(masterList);
      }

      const detailList = await getDataWithComp('API_1002_WHDOC_D', 'ORDER BY Line ASC');
      if (detailList && detailList.length > 0) {
        setDataDetailList(detailList);
      }

      // หาค่าสูงของ WHDocNo ใน WHDOC_H สำหรับ OD (ใบออเดอร์สินค้า)
      const findMaxOdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '4' ORDER BY WHDoc_No DESC`);
      const maxOd = getMaxOdNo(findMaxOdNo);
      setMaxOdNo(maxOd);
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I')
  };

  const onPageUpdate = (whDocNo) => {
    setMaxOdNo(whDocNo);
    setMode('U')
  };

  return (
    <div>
      <div className="OrderProduct">
        <div className="wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="container">
              <div className="page-inner">
                {mode === 'S' ? (
                  <Main
                    name={'ใบออเดอร์สินค้า'}
                    dataMasterList={dataMasterList}
                    dataDetailList={dataDetailList}
                    onPageInsert={() => onPageInsert()}
                    onPageUpdate={(whDocNo) => onPageUpdate(whDocNo)}
                  />
                ) : (
                  <Form
                    callInitialize={initialize}
                    mode={mode}
                    name={'ใบออเดอร์สินค้า'}
                    whDocNo={maxOdNo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;