
export const InitialDataTbSetItem = () => {
    return {
        item_id: null,
        comp_id: window.localStorage.getItem('company'), // Int
        item_barcode: null,
        item_code: null,
        item_name: null,
        item_name_en: null,
        item_short_name: null,
        item_unit_in: null,
        item_unit_st: null,
        item_unit_out: null,
        item_cost: null,
        item_price: null,
        item_pricea: null,
        item_priceb: null,
        item_brand: null,
        item_series: null,
        item_group: null,
        item_cat: null,
        item_colour: null,
        item_size: null,
        item_status: null,
        item_popoint: null,
        item_maxonhand: null,
        ap_code: null,
        item_gold: null,
        item_onhand: null,
        item_wh: null
    }
}