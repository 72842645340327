import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TbSetItemGroup/Main';
import InitialDataModalTbSetItemGroup from '../../components/Modal/InitialDataModalTbSetItemGroup';

// Utils
import {
  getAlert,
  getData,
} from '../../../utils/SamuiUtils';
import { InitialDataTbSetItemGroup } from '../../../model/InitialData/InitialDataTbSetItemGroup';

function TbSetItemGroup() {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formData, setFormData] = useState(InitialDataTbSetItemGroup());


  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = async () => setShowModal(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime();
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      const masterList = await getData("Tb_Set_ItmGroup", "1=1");
      if (masterList && masterList.length > 0) {
        masterList.sort((a, b) => a.Colour_Id - b.Colour_Id);
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I');
    handleShow();
  };

  const onRowSelected = async (item) => {
    setMode('U');
    setFormData({
      group_code: item.Group_Code,
      group_name: item.Group_Name,
      group_status: item.Group_Status,
      group_gold: item.Group_Gold,
      comp_id: item.Comp_Id,
    });
    setSelectedCar(item);
    handleShow();
  };

  return (
    <div className="TransportInformation">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <Main
                masterList={dataMasterList}
                name={'ข้อมูลกรุ๊ปสินค้า'}
                onPageInsert={onPageInsert}
                onRowSelected={onRowSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <InitialDataModalTbSetItemGroup
        showModal={showModal}
        handleClose={handleClose}
        callInitialize={initialize}
        mode={mode}
        name={'ข้อมูลกรุ๊ปสินค้า'}
        formData={formData}
        setFormData={setFormData}
        selectedCar={selectedCar}
      />
    </div>
  );
}

export default TbSetItemGroup;