export const whDocDetailModel = (id) => ({
    id: id,
    dtId: null,
    whDocId: null,
    line: null,
    itemId: null,
    itemCode: null,
    itemName: null,
    itemOnHand: null,
    itemQty: null,
    itemIncrease: null,
    itemDecrease: null,
    itemOrder: null,
    itemBalance: null,
    itemUnit: null,
    itemPriceUnit: null,
    itemDiscount: null,
    itemDisType: null,
    itemTotal: null,
    docType: null,
    refDocId: null,
    refDocNo: null
});