import React from 'react';
import './../../../assets/css/purchase/datatable.css';

const DataTableInitialDataDebtor = ({ currentItems, onRowSelected, currentPage, handlePageChange, dataMasterList, itemsPerPage, fieldMappings }) => {
    const renderPageNumbers = () => {
        const totalPages = Math.ceil(dataMasterList.length / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <a href="#" className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className='col-12'>
            <div className="card">
                <div className="table-responsive">
                    <table id="basic-datatables" className="table table-striped table-hover">
                        <thead className="thead-dark">
                            <tr>
                                {/* ข้อมูลลูกหนี้ */}
                                <th className="text-center" style={{ width: '2%' }}>#</th>
                                <th className="text-center" style={{ width: '10%' }}>รหัสลูกหนี้</th>
                                <th className="text-center" style={{ width: '43%' }}>ชื่อลูกหนี้</th>
                                <th className="text-center" style={{ width: '5%' }}>AR_Type</th>
                                {/* <th className="text-center" style={{ width: '10%' }}>AR_TaxNo</th> */}
                                {/* <th className="text-center" style={{ width: '15%' }}>ที่อยู่</th> */}
                                {/* <th className="text-center" style={{ width: '10%' }}>เบอร์โทร</th> */}
                                <th className="text-center" style={{ width: '5%' }}>สถานะ</th>
                                {/* <th className="text-center" style={{ width: '5%' }}>AR_Branch</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>AR_BT_Name</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>AR_ADDLAT</th> */}
                                {/* <th className="text-center" style={{ width: '5%' }}>AR_ADDLAN</th> */}
                                <th className="text-center" style={{ width: '15%' }}>AR_RefSaleName</th>
                                <th className="text-center" style={{ width: '10%' }}>AR_Credit_Term</th>
                                <th className="text-center" style={{ width: '10%' }}>AR_Credit_Limit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={index} onClick={() => onRowSelected(item)} style={{ cursor: 'pointer' }}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item[fieldMappings.ARCode]}</td>
                                        <td className="text-left">{item[fieldMappings.ARName]}</td>
                                        <td className="text-center">{item[fieldMappings.ARType]}</td>
                                        {/* <td className="text-center">{item[fieldMappings.ARTaxNo]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ARAdd1]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ARTel]}</td> */}
                                        <td className="text-center">{item[fieldMappings.ARStatus]}</td>
                                        {/* <td className="text-center">{item[fieldMappings.ARBranch]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ARBTName]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ARAddLat]}</td> */}
                                        {/* <td className="text-center">{item[fieldMappings.ARAddLan]}</td> */}
                                        <td className="text-center">{item[fieldMappings.ARRefSaleName]}</td>
                                        <td className="text-center">{item[fieldMappings.ARCreditTerm]}</td>
                                        <td className="text-center">{item[fieldMappings.ARCreditLimit]}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">
                                        <center>
                                            <h5>ไม่พบข้อมูล</h5>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                                <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`paginate_button page-item next ${currentPage === Math.ceil(dataMasterList.length / itemsPerPage) ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTableInitialDataDebtor;
