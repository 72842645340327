import Axios from "axios";
import { useEffect, useState } from "react";
import { deleteDetail, getAlert } from "../../../utils/SamuiUtils";
import Breadcrumbs from "../Breadcrumbs";
import FormAction from "../Actions/FormAction";

const InitialDataModal = ({ showModal, handleClose, callInitialize, mode, name, formData, setFormData, selectedCar, pklList }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);

  useEffect(() => {
    // เช็คว่าเป็นการสร้างข้อมูลใหม่หรือแก้ไขข้อมูล
    if (mode === 'I') {
      const initialData = {
        car_id: "",
        car_no: "",
        car_name: "",
        car_remark: "",
        car_status: "Y", // Set default value to "Y"
        wh_id: "",
        comp_id: localStorage.getItem('company')
      };
      // กำหนดค่าเริ่มต้นให้กับ form data
      setFormData(initialData);
      // กำหนดค่าเริ่มต้นให้กับ initial form data
      setInitialFormData(initialData);
      // ถ้าเป็นการแก้ไขข้อมูล
    } else if (mode === 'U' && selectedCar) {
      const initialData = {
        car_id: selectedCar.Car_id,
        car_no: selectedCar.Car_No,
        car_name: selectedCar.Car_Name,
        car_remark: selectedCar.Car_Remark,
        car_status: selectedCar.Car_Status,
        wh_id: selectedCar.WH_Id,
        comp_id: selectedCar.Comp_Id
      };
      // อัปเดตค่าให้กับ form data
      setFormData(initialData);
      // อัปเดตค่าให้กับ initial form data
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedCar]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInsertCar = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-tb-set-car`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีทะเบียนนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleUpdateCar = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-tb-set-car`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      console.error("Error updating car:", error);
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีทะเบียนนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleInsertWh = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-tb-set-wh`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleUpdateWh = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-tb-set-wh`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      console.error("Error updating warehouse:", error);
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleDelete = async () => {
    if (pklList.length > 0) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ เนื่องจากมีการใช้งานอยู่");
      return;
    }
    if (!selectedCar) {
      console.error("No car selected for deletion");
      return;
    }
    try {
      await deleteDetail('Tb_Set_Car', `WHERE Car_id = ${selectedCar.Car_id}`);
      await deleteDetail('Tb_Set_WH', `WHERE WH_Code = '${selectedCar.Car_No}'`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
      await handleClose();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    if (!formData.car_no || !formData.car_name || !formData.car_remark) {
      getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วนทุกช่อง");
      return;
    }
    // ตรวจสอบว่าข้อมูลที่แก้ไขว่าซ้ำกับข้อมูลเดิมหรือไม่
    if (
      formData.car_no === initialFormData.car_no &&
      formData.car_name === initialFormData.car_name &&
      formData.car_remark === initialFormData.car_remark &&
      formData.car_status === initialFormData.car_status
    ) {
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return;
    }
    try {
      if (mode === 'I') {
        await handleInsertCar();
        await handleInsertWh();
      } else if (mode === 'U') {
        await handleUpdateCar();
        await handleUpdateWh();
      }
      setFormData({
        car_id: "",
        car_no: "",
        car_name: "",
        car_remark: "",
        car_status: "Y", // Set default value to "Y"
        wh_id: "",
        comp_id: localStorage.getItem('company')
      });
      await callInitialize();
      await handleClose();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  };

  return (
    <div
      className={`modal ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="card-title">{mode === 'I' ? `เพิ่ม${name}` : `แก้ไข${name}`}</h4>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="shadow-lg rounded">
                <div className="p-3">
                  <div className="col-12 my-1 mt-2">
                    <div className="d-flex align-items-center">
                      <label className="text-black">ทะเบียนรถ : </label>
                      <input
                        type="text"
                        className="form-control input-spacing"
                        name="car_no"
                        value={formData.car_no || ""}
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex align-items-center">
                      <label className="text-black">ชื่อรถ : </label>
                      <input
                        type="text"
                        className="form-control input-spacing"
                        name="car_name"
                        value={formData.car_name || ""}
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex align-items-center">
                      <label className="text-black">หมายเหตุ : </label>
                      <input
                        type="text"
                        className="form-control input-spacing"
                        name="car_remark"
                        value={formData.car_remark || ""}
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex align-items-center">
                      <label className="text-black">สถานะรถ : </label>
                      <select
                        className="form-select"
                        name="car_status"
                        value={formData.car_status || "Y"} // Set default value to "Y"
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12" />
                </div>
                <div className="row">
                  <FormAction
                    onSubmit={handleSubmit}
                    onUpdate={handleSubmit}
                    onDelete={handleDelete}
                    mode={mode}
                    pklList={pklList}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialDataModal;