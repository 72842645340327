import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/Debtor/Main';
import Form from '../../components/InitialData/Debtor/Form';

// Utils
import {
    getData,
    getAlert,
} from '../../../utils/SamuiUtils';
import { InitialDataDebtor } from '../../../model/InitialData/InitialDataDebtor';

const Debtor = () => {

    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [formData, setFormData] = useState(InitialDataDebtor());

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            setMode('S');
            fetchRealtime(); // เรียกใช้งาน fetchRealtime เพื่อโหลดข้อมูลเมื่อ component โหลดครั้งแรก
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const fetchRealtime = async () => {
        try {
            // ดึงข้อมูลจาก Tb_Set_AR ทั้งหมด
            const masterList = await getData("Tb_Set_AR", "1=1");
            if (masterList && masterList.length > 0) {
                masterList.sort((a, b) => a.AR_Id - b.AR_Id);
                setDataMasterList(masterList);
            }
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onPageInsert = () => {
        setMode('I')
    };

    const onRowSelected = (item) => {
        setMode('U');
        setFormData({
            ar_id: item.Ar_Id, // Int
            comp_id: window.localStorage.getItem('company'), // Int
            ar_code: item.Ar_Code,
            ar_name: item.Ar_Name,
            ar_type: item.Ar_Type, // Int
            ar_shortname: item.Ar_ShortName,
            ar_taxno: item.Ar_TaxNo,
            ar_add1: item.Ar_Add1,
            ar_add2: item.Ar_Add2,
            ar_add3: item.Ar_Add3,
            ar_province: item.Ar_Province,
            ar_zipcode: item.Ar_Zipcode,
            ar_contact1: item.Ar_Contact,
            ar_contact2: item.Ar_Contact2,
            ar_tel: item.Ar_Tel,
            ar_tel2: item.Ar_Tel2,
            ar_group: item.Ar_Group,
            ar_refsalecode: item.Ar_RefSaleCode,
            ar_area: item.Ar_Area,
            ar_status: item.Ar_Status,
            ar_credit: item.Ar_Credit, // Int
            ar_vat: item.Ar_Vat,    // Int
            ar_discount: item.Ar_Discount,
            ar_branch: item.Ar_Branch,
            ar_bt_name: item.Ar_BT_Name,
            ar_addlat: item.AR_ADDLAT,
            ar_addlan: item.AR_ADDLAN,
            ar_refsaleName: item.AR_RefSaleName,
            ar_credit_term: item.AR_Credit_Term,
            ar_credit_limit:item.AR_Credit_Limit,
        })
        setSelectedData(item);
    };

    return <div className="Creditor">
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {
                            mode === 'S' ? (<Main
                                masterList={dataMasterList}
                                name={'ข้อมูลลูกหนี้'}
                                onPageInsert={() => onPageInsert()}
                                onRowSelected={(item) => onRowSelected(item)}
                            />
                            ) : (<Form
                                callInitialize={initialize}
                                mode={mode}
                                name={'ข้อมูลลูกหนี้'}
                                formData={formData}
                                setFormData={setFormData}
                                selectedData={selectedData}
                            />
                            )}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Debtor;
