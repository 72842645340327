import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TbSetItem/Main';
import Form from '../../components/InitialData/TbSetItem/Form';

// Utils
import {
    getData,
    getAlert,
} from '../../../utils/SamuiUtils';
import { InitialDataTbSetItem } from '../../../model/InitialData/InitialDataTbSetItem';
import { formatThaiDateUi } from '../../../utils/DateUtils';

const TbSetItem = () => {

    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [formData, setFormData] = useState(InitialDataTbSetItem());

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            setMode('S');
            fetchRealtime();
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const fetchRealtime = async () => {
        try {
            // ดึงข้อมูลจาก Tb_Set_Employee ทั้งหมด
            const masterList = await getData("Tb_Set_Item", "1=1");
            if (masterList && masterList.length > 0) {
                masterList.sort((a, b) => a.Emp_Id - b.Emp_Id);
                setDataMasterList(masterList);
            }
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onPageInsert = () => {
        setMode('I')
    };

    const onRowSelected = (item) => {
        setMode('U');
        setFormData({
            item_id: item.Item_Id,
            comp_id: item.Comp_Id,
            item_barcode: item.Item_Barcode,
            item_code: item.Item_Code,
            item_name: item.Item_Name,
            item_name_en: item.Item_Name_EN,
            item_short_name: item.Item_Short_Name,
            item_unit_in: item.Item_Unit_IN,
            item_unit_st: item.Item_Unit_ST,
            item_unit_out: item.Item_Unit_OUT,
            item_cost: item.Item_Cost,
            item_price: item.Item_Price,
            item_pricea: item.Item_PriceA,
            item_priceb: item.Item_PriceB,
            item_brand: item.Item_Brand,
            item_series: item.Item_Series,
            item_group: item.Item_Group,
            item_cat: item.Item_Cat,
            item_colour: item.Item_Colour,
            item_size: item.Item_Size,
            item_status: item.Item_Status,
            item_popoint: item.Item_PoPoint,
            item_maxonhand: item.Item_MaxOnhand,
            ap_code: item.AP_Code,
            item_gold: item.Item_Gold,
            item_onhand: item.Item_Onhand,
            item_wh: item.Item_WH
        })
        setSelectedData(item);
    };

    return <div className="Creditor">
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <div className="container">
                    <div className="page-inner">
                        {
                            mode === 'S' ? (<Main
                                masterList={dataMasterList}
                                name={'ข้อมูลไอเท็ม'}
                                onPageInsert={() => onPageInsert()}
                                onRowSelected={(item) => onRowSelected(item)}
                            />
                            ) : (<Form
                                callInitialize={initialize}
                                mode={mode}
                                name={'ข้อมูลไอเท็ม'}
                                formData={formData}
                                setFormData={setFormData}
                                selectedData={selectedData}
                            />
                            )}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default TbSetItem;
