import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TbSetItemTransType/Main';
import InitialDataModalTbSetItemTransType from '../../components/Modal/InitialDataModalTbSetItemTransType';

// Utils
import {
  getAlert,
  getData,
} from '../../../utils/SamuiUtils';
import { InitialDataTbSetItemTransType } from '../../../model/InitialData/InitialDataTbSetItemTransType';

function TbSetItemTransType() {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formData, setFormData] = useState(InitialDataTbSetItemTransType());


  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = async () => setShowModal(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime();
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      const masterList = await getData("Tb_Set_TransType", "1=1");
      if (masterList && masterList.length > 0) {
        masterList.sort((a, b) => a.Trans_TypeID - b.Trans_TypeID);
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I');
    handleShow();
  };

  const onRowSelected = async (item) => {
    setMode('U');
    setFormData({
      trans_typeid: item.Trans_TypeID,
      trans_name: item.Trans_TypeName,
      trans_status: item.Trans_Status
    });
    setSelectedCar(item);
    handleShow();
  };

  return (
    <div className="TbSetItemTransType">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <Main
                masterList={dataMasterList}
                name={'ข้อมูลทรานส์ไทป์'}
                onPageInsert={onPageInsert}
                onRowSelected={onRowSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <InitialDataModalTbSetItemTransType
        showModal={showModal}
        handleClose={handleClose}
        callInitialize={initialize}
        mode={mode}
        name={'ข้อมูลทรานส์ไทป์'}
        formData={formData}
        setFormData={setFormData}
        selectedCar={selectedCar}
      />
    </div>
  );
}

export default TbSetItemTransType;