import React from 'react';
import './../../../assets/css/purchase/datatable.css';

const DataTableInitialDataCreditor = ({ currentItems, onRowSelected, currentPage, handlePageChange, dataMasterList, itemsPerPage, fieldMappings }) => {
    const renderPageNumbers = () => {
        const totalPages = Math.ceil(dataMasterList.length / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <a href="#" className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className='col-12'>
            <div className="card">
                <div className="table-responsive">
                    <table id="basic-datatables" className="table table-striped table-hover">
                        <thead className="thead-dark">
                            <tr>
                                {/* ข้อมูลรถขนส่ง */}
                                <th className="text-center" style={{ width: '2%' }}>#</th>
                                <th className="text-center" style={{ width: '8%' }}>รหัสเจ้าหนี้</th>
                                <th className="text-center" style={{ width: '30%' }}>ชื่อเจ้าหนี้</th>
                                <th className="text-center" style={{ width: '1%' }}>AP_Type</th>
                                <th className="text-center" style={{ width: '10%' }}>AP_TaxNo</th>
                                <th className="text-center" style={{ width: '15%' }}>ที่อยู่</th>
                                <th className="text-center" style={{ width: '11%' }}>เบอร์โทร</th>
                                <th className="text-center" style={{ width: '3%' }}>สถานะ</th>
                                <th className="text-center" style={{ width: '10%' }}>AP_Branch</th>
                                <th className="text-center" style={{ width: '10%' }}>AP_BT_Name</th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={index} onClick={() => onRowSelected(item)} style={{ cursor: 'pointer' }}>
                                        {/* ข้อมูลรถขนส่ง */}
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item[fieldMappings.APCode]}</td>
                                        <td className="text-left">{item[fieldMappings.APName]}</td>
                                        <td className="text-center">{item[fieldMappings.APType]}</td>
                                        <td className="text-center">{item[fieldMappings.APTaxNo]}</td>
                                        <td className="text-center">{item[fieldMappings.APAdd1]}</td>
                                        <td className="text-center">{item[fieldMappings.APTel]}</td>
                                        <td className="text-center">{item[fieldMappings.APStatus]}</td>
                                        <td className="text-center">{item[fieldMappings.APBranch]}</td>
                                        <td className="text-center">{item[fieldMappings.APBTName]}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">
                                        <center>
                                            <h5>ไม่พบข้อมูล</h5>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                                <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`paginate_button page-item next ${currentPage === Math.ceil(dataMasterList.length / itemsPerPage) ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTableInitialDataCreditor;
