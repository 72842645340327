export const InitialDataCreditor = () => {
    return {
        ap_id: null, // Int
        comp_id: window.localStorage.getItem('company'), // Int
        ap_code: null,
        ap_name: null,
        ap_type: null, // Int
        ap_shortname: null,
        ap_taxno: null,
        ap_add1: null,
        ap_add2: null,
        ap_add3: null,
        ap_province: null,
        ap_zipcode: null,
        ap_contact: null,
        ap_contact2: null,
        ap_tel: null,
        ap_tel2: null,
        ap_group: null,
        ap_refsalecode: null,
        ap_area: null,
        ap_status: null,
        ap_credit: null, // Int
        ap_vat: null,    // Int
        ap_discount: null,
        ap_branch: null,
        ap_bt_name: null,
    }
}