import React from 'react';
import './../../../assets/css/purchase/datatable.css';

const DataTableInitialDataCompany = ({ currentItems, onRowSelected, currentPage, handlePageChange, dataMasterList, itemsPerPage, fieldMappings }) => {
    const renderPageNumbers = () => {
        const totalPages = Math.ceil(dataMasterList.length / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <a href="#" className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className='col-12'>
            <div className="card">
                <div className="table-responsive">
                    <table id="basic-datatables" className="table table-striped table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th className="text-center" style={{ width: '2%' }}>#</th>
                                <th className="text-center" style={{ width: '8%' }}>Comp_Code</th>
                                <th className="text-center" style={{ width: '40%' }}>Comp_Name_TH</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Name_EN</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_ShortName</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_TaxNo</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Add1</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Add2</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Add3</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Province</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Post</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Tel1</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Tel2</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Contact1</th>
                                <th className="text-center" style={{ width: '5%' }}>Comp_Contact2</th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={index} onClick={() => onRowSelected(item)} style={{ cursor: 'pointer' }}>
                                        {/* ข้อมูลรถขนส่ง */}
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item[fieldMappings.CompCode]}</td>
                                        <td className="text-left">{item[fieldMappings.CompNameTH]}</td>
                                        <td className="text-center">{item[fieldMappings.CompNameEN]}</td>
                                        <td className="text-center">{item[fieldMappings.CompShortName]}</td>
                                        <td className="text-center">{item[fieldMappings.CompTaxNo]}</td>
                                        <td className="text-center">{item[fieldMappings.CompAdd1]}</td>
                                        <td className="text-center">{item[fieldMappings.CompAdd2]}</td>
                                        <td className="text-center">{item[fieldMappings.CompAdd3]}</td>
                                        <td className="text-center">{item[fieldMappings.CompProvince]}</td>
                                        <td className="text-center">{item[fieldMappings.CompPost]}</td>
                                        <td className="text-center">{item[fieldMappings.CompTel1]}</td>
                                        <td className="text-center">{item[fieldMappings.CompTel2]}</td>
                                        <td className="text-center">{item[fieldMappings.CompContact1]}</td>
                                        <td className="text-center">{item[fieldMappings.CompContact2]}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">
                                        <center>
                                            <h5>ไม่พบข้อมูล</h5>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                                <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`paginate_button page-item next ${currentPage === Math.ceil(dataMasterList.length / itemsPerPage) ? 'disabled' : ''}`}>
                                    <a href="#" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTableInitialDataCompany;
