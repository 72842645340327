import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/Company/Main';
import Form from '../../components/InitialData/Company/Form';

// Utils
import {
  getAlert,
  getCompany,
} from '../../../utils/SamuiUtils';
import { InitialDataCompany } from '../../../model/InitialData/InitialDataCompany';

const Company = () => {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [formData, setFormData] = useState(InitialDataCompany());

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime(); 
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      // ดึงข้อมูลจาก Tb_Set_AR ทั้งหมด
      const masterList = await getCompany();
      if (masterList && masterList.length > 0) {
        masterList.sort((a, b) => a.comp_Id - b.comp_Id);
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I')
  };

  const onRowSelected = (item) => {
    setMode('U');
    setFormData({
      comp_id: item.comp_id, // Int
      comp_code: item.comp_code,
      comp_name_th: item.comp_name_th,
      comp_name_en: item.comp_name_en,
      comp_shortname: item.comp_shortname,
      comp_taxno: item.comp_taxno,
      comp_add1: item.comp_add1,
      comp_add2: item.comp_add2,
      comp_add3: item.comp_add3,
      comp_province: item.comp_province,
      comp_post: item.comp_post,
      comp_tel1: item.comp_tel1,
      comp_tel2: item.comp_tel2,
      comp_contact1: item.comp_contact1,
      comp_contact2: item.comp_contact2,
    })
    setSelectedData(item);
  };

  return <div className="Creditor">
    <div className="wrapper">
      <Sidebar />
      <div className="main-panel">
        <div className="container">
          <div className="page-inner">
            {
              mode === 'S' ? (<Main
                masterList={dataMasterList}
                name={'ข้อมูลบริษัท'}
                onPageInsert={() => onPageInsert()}
                onRowSelected={(item) => onRowSelected(item)}
              />
              ) : (<Form
                callInitialize={initialize}
                mode={mode}
                name={'ข้อมูลบริษัท'}
                formData={formData}
                setFormData={setFormData}
                selectedData={selectedData}
                dataMasterList={dataMasterList}
              />
              )}
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default Company;
