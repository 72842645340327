import React from 'react';
import './../../../assets/css/purchase/datatable.css';

const DataTableInitialDataItemGroup = ({ currentItems, onRowSelected, currentPage, handlePageChange, dataMasterList, itemsPerPage, fieldMappings }) => {
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(dataMasterList.length / itemsPerPage);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
          <a href="#" className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }
    return pages;
  };

  return (
    <div className='col-12'>
      <div className="card">
        <div className="table-responsive">
          <table id="basic-datatables" className="table table-striped table-hover">
            <thead className="thead-dark">
              <tr>
                <th className="text-center" style={{ width: '2%' }}>#</th>
                <th className="text-center" style={{ width: '15%' }}>Group_Code</th>
                <th className="text-center" style={{ width: '65%' }}>Group_Name</th>
                <th className="text-center" style={{ width: '3%' }}>Group_Status</th>
                <th className="text-center" style={{ width: '15%' }}>Group_Gold</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index} onClick={() => onRowSelected(item)} style={{ cursor: 'pointer' }}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{item[fieldMappings.GroupCode]}</td>
                    <td className="text-left">{item[fieldMappings.GroupName]}</td>
                    <td className="text-center">{item[fieldMappings.GroupStatus]}</td>
                    <td className="text-center">{item[fieldMappings.GroupGold]}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">
                    <center>
                      <h5>ไม่พบข้อมูล</h5>
                    </center>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-end">
            <div className="dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                  <a href="#" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
                </li>
                {renderPageNumbers()}
                <li className={`paginate_button page-item next ${currentPage === Math.ceil(dataMasterList.length / itemsPerPage) ? 'disabled' : ''}`}>
                  <a href="#" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTableInitialDataItemGroup;