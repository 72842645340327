
export const InitialDataEmployee = () => {
    return {
        emp_id: null,
        emp_code: null,
        emp_prefix: null,
        emp_name: null,
        emp_dept: null,
        emp_position: null,
        emp_position_detail: null,
        emp_startdate: null,
        emp_tel: null,
        emp_status: null,
        emp_uuser: null,
        emp_ppass: null,
        emp_pin: null,
        gold_so: null,
        comp_id: window.localStorage.getItem('company'), // Int
    }
}