import Axios from 'axios';
import React, { useState, useEffect } from 'react';

// Components
import TeasuryTable from '../../components/Content/TeasuryTable';
import BolModal from '../Modal/BolModal';
import OrderModal from '../Modal/OrderModal';

// Utils
import {
    getDataWithComp,
    getAlert,
    getMaxWdNo,
    getMaxOdNo
} from '../../../utils/SamuiUtils';

import { formatThaiDateUi } from '../../../utils/DateUtils';

const ImportModal = ({
    onConfirmImportSelection,
    showImportModal,
    handleImportClose,
    name,
    pickingList,
    selectedImports
}) => {
    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [dataDetailList, setDataDetailList] = useState([]);
    const [custNames, setCustNames] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    // สำหรับค้นหาเลขที่เอกสารหรือชื่อลูกค้า และ สำหรับค้นหารหัสสินค้าหรือชื่อสินค้า
    const [searchTermDoc, setSearchTermDoc] = useState('');
    const [searchTermItem, setSearchTermItem] = useState('');

    // เก็บค่า maxWhDocNo
    const [maxWdNo, setMaxWdNo] = useState();
    const [maxOdNo, setMaxOdNo] = useState();

    useEffect(() => {
        initialize();

        if (showImportModal) {
            // ตั้งค่า selectedItems เมื่อมีการเปิด modal
            setSearchTermDoc('');
            setSearchTermItem('');
            setSelectedItems(selectedImports);
        }

    }, [showImportModal]);

    useEffect(() => {
        const fetchCustNames = async () => {
            const names = {};
            for (const data of dataMasterList) {
                const custName = await getCustnameByCustId(data.WHDoc_Cust_Id);
                names[data.WHDoc_Cust_Id] = custName;
            }
            setCustNames(names);
        };

        fetchCustNames();
    }, [dataMasterList]);

    const initialize = async () => {
        try {
            setDataDetailList([]);
            setShowBolModal(false);
            setShowOrderModal(false);
            setMode('S');

            const masterList = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-wd-by-car-id`, {
                car_id: pickingList.pkCarId
            }, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });
            if (masterList.data && masterList.data.length > 0) {
                setDataMasterList(masterList.data);
            }

            const findMaxWdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '3' ORDER BY WHDoc_No DESC`);
            const maxWd = getMaxWdNo(findMaxWdNo);
            setMaxWdNo(maxWd);

            const findMaxOdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '4' ORDER BY WHDoc_No DESC`);
            const maxOd = getMaxOdNo(findMaxOdNo);
            setMaxOdNo(maxOd);
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onRowSelected = async (fromData) => {
        const filteredDetail = await getDataWithComp(
            'API_1002_WHDOC_D',
            `
            AND WHDoc_Id = ${fromData.WHDoc_Id} 
            ORDER BY Line ASC
            `
        );
        if (filteredDetail && filteredDetail.length > 0) {
            setDataDetailList(filteredDetail);
        } else {
            setDataDetailList([]);
        }
    };

    const getCustnameByCustId = async (custId) => {
        if (!custId)
            return '-';

        const custName = await getDataWithComp(
            'Tb_Set_AR', `AND AR_Id = ${custId}`
        );
        if (custName && custName.length > 0) {
            return custName[0].AR_Name;
        } else {
            return '-';
        }
    };

    // SET BOL
    const [showBolModal, setShowBolModal] = useState(false);
    const handleBolShow = (mode) => {
        setMode(mode);
        setShowBolModal(true);
    };
    const handleBolClose = () => {
        setShowBolModal(false);
        initialize();
    };

    // SET ORDER
    const [showOrderModal, setShowOrderModal] = useState(false);
    const handleOrderShow = (mode) => {
        setMode(mode);
        setShowOrderModal(true);
    };
    const handleOrderClose = () => {
        setShowOrderModal(false);
        initialize();
    };

    const handleEdit = (mode, data) => {
        setMode('U');

        // if (data.WHDoc_Type === 3) {
        //     setMaxWdNo(data.WHDoc_No);
        //     handleBolShow();
        // } else if (data.WHDoc_Type === 4) {
        //     setMaxOdNo(data.WHDoc_No);
        //     handleOrderShow();
        // } else {
        //     getAlert('FAILED', 'ข้อมูลไม่ถูกต้อง');
        // }

        setMaxWdNo(data.WHDoc_No);
        handleBolShow(mode);
    };

    const handleCheckboxChange = (od) => {
        if (selectedItems.some(item => item.WHDoc_No === od.WHDoc_No)) {
            setSelectedItems(selectedItems.filter(item => item.WHDoc_No !== od.WHDoc_No));
        } else {
            setSelectedItems([...selectedItems, od]);
        }
    };

    const handleConfirmSelection = () => {
        onConfirmImportSelection(selectedItems);
    };

    // การค้นหารายการใน dataMasterList
    const filteredMasterList = dataMasterList.filter(data =>
        data.WHDoc_No.includes(searchTermDoc) ||
        (custNames[data.WHDoc_Cust_Id] || '').includes(searchTermDoc)
    );

    // การค้นหารายการใน dataDetailList
    const filteredDetailList = dataDetailList.filter(data =>
        data.Item_Code.includes(searchTermItem) ||
        data.Item_Name.includes(searchTermItem)
    );

    return (
        <>
            <div className={`modal ${showImportModal ? 'show' : ''}`} style={{ display: showImportModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">

                        {/* Header */}
                        <div className="modal-header">
                            <div className="d-flex justify-content-between align-items-center w-100" style={{ padding: '10px' }}>
                                <div className="d-flex align-items-center">
                                    <h4 className="card-title">นำเข้าสินค้า</h4>
                                </div>
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        className="btn custom-button btn-lg me-2"
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => handleBolShow("I")}
                                    >
                                        <i className="fa fa-plus"></i> สร้างใบเบิกสินค้า
                                    </button>
                                    {showBolModal && (
                                        <BolModal
                                            showBolModal={showBolModal}
                                            handleBolClose={handleBolClose}
                                            callInitialize={initialize}
                                            mode={mode}
                                            name={name}
                                            whDocNo={maxWdNo}
                                            pickingList={pickingList}
                                        />
                                    )}
                                    <button
                                        type="button"
                                        className="btn custom-button btn-lg"
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => handleOrderShow("I")}
                                    >
                                        <i className="fa fa-plus"></i> สร้างใบออเดอร์สินค้า
                                    </button>
                                    {showOrderModal && (
                                        <OrderModal
                                            showOrderModal={showOrderModal}
                                            handleOrderClose={handleOrderClose}
                                            callInitialize={initialize}
                                            mode={mode}
                                            name={name}
                                            whDocNo={maxOdNo}
                                            pickingList={pickingList}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Body */}
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5" style={{ borderRight: '2px solid #c7c8c9' }}>
                                    <div className="form-group">
                                        <input
                                            style={{ width: '100%' }}
                                            type="text"
                                            className="form-control"
                                            placeholder="ค้นหาเลขที่เอกสาร หรือ ชื่อลูกค้า"
                                            value={searchTermDoc}
                                            onChange={(e) => setSearchTermDoc(e.target.value)} // เพิ่มการตั้งค่าค่าค้นหา
                                        />
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="form-group">
                                        <input
                                            style={{ width: '100%' }}
                                            type="text"
                                            className="form-control"
                                            placeholder="ค้นหารหัสสินค้า หรือ ชื่อสินค้า"
                                            value={searchTermItem}
                                            onChange={(e) => setSearchTermItem(e.target.value)} // เพิ่มการตั้งค่าค่าค้นหา
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* Left Side: Document List */}
                                <div className="col-5" style={{ borderRight: '2px solid #c7c8c9' }}>
                                    <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        <table id="basic-datatables" className="table table-striped table-hover">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th className="text-center" style={{ width: '5%' }}>
                                                        <input
                                                            style={{ width: '20px' }}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                setSelectedItems(checked ? filteredMasterList : []);
                                                            }}
                                                            checked={filteredMasterList.length > 0 && selectedItems.length === filteredMasterList.length} />
                                                    </th>
                                                    <th className="text-center" style={{ width: '30%' }}>วันที่</th>
                                                    <th className="text-center" style={{ width: '20%' }}>เลขใบเบิก</th>
                                                    <th className="text-center" style={{ width: '40%' }}>จากคลัง</th>
                                                    <th className="text-center" style={{ width: '5%' }}>
                                                        <i className="fa fa-edit" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredMasterList.length > 0 ? (
                                                    filteredMasterList.map((data, index) => (
                                                        <tr
                                                            key={data.docId || index + 1}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => onRowSelected(data)}
                                                        >
                                                            <td className="text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedItems.some(item => item.WHDoc_No === data.WHDoc_No)}
                                                                    onChange={() => handleCheckboxChange(data)}
                                                                    style={{ width: '20px', cursor: 'pointer' }}
                                                                />
                                                            </td>
                                                            <td className="text-center">{formatThaiDateUi(data.WHDoc_Date)}</td>
                                                            <td>{data.WHDoc_No}</td>
                                                            {/* <td>{custNames[data.WHDoc_Cust_Id] || '-'}</td> */}
                                                            <td className="text-center">{data.T_WH_Name}</td>
                                                            <td className="text-center">
                                                                <button className="btn btn-sm" onClick={() => handleEdit("U", data)}>
                                                                    <i className="fa fa-edit" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">ไม่มีข้อมูล</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* Right Side: Item List */}
                                <div className="col-7">
                                    <TeasuryTable dataList={filteredDetailList} />
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn text-white mx-3"
                                onClick={handleConfirmSelection}
                                style={{
                                    backgroundColor: 'rgb(239, 108, 0)',
                                    whiteSpace: 'nowrap',
                                    padding: '10px',
                                    fontSize: '15px'
                                }}
                            >
                                นำเข้าสินค้า
                            </button>
                            <button type="button" className="btn btn-danger" onClick={handleImportClose}>
                                ปิด
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ImportModal;