import Axios from "axios";
import { useEffect, useState } from "react";
import { deleteDetail, getAlert } from "../../../utils/SamuiUtils";
import Breadcrumbs from "../Breadcrumbs";
import FormAction from "../Actions/FormAction";

const InitialDataModalTbSetItemSize = ({ showModal, handleClose, callInitialize, mode, name, formData, setFormData, selectedCar, pklList }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);

  useEffect(() => {
    // เช็คว่าเป็นการสร้างข้อมูลใหม่หรือแก้ไขข้อมูล
    if (mode === 'I') {
      const initialData = {
        size_code: "",
        size_name: "",
        size_status: "Y",
        comp_id: localStorage.getItem('company')
      };
      // กำหนดค่าเริ่มต้นให้กับ form data
      setFormData(initialData);
      // กำหนดค่าเริ่มต้นให้กับ initial form data
      setInitialFormData(initialData);
      // ถ้าเป็นการแก้ไขข้อมูล
    } else if (mode === 'U' && selectedCar) {
      const initialData = {
        size_id: selectedCar.Size_Id,
        size_code: selectedCar.Size_Code,
        size_name: selectedCar.Size_Name,
        size_status: selectedCar.Size_Status,
        comp_id: selectedCar.Comp_Id
      };
      // อัปเดตค่าให้กับ form data
      setFormData(initialData);
      // อัปเดตค่าให้กับ initial form data
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedCar]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInsertTbSetSize = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-item-size`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีทะเบียนนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleUpdateTbSetSize = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-item-size`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      console.error("Error updating car:", error);
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีทะเบียนนี้อยู่ในระบบแล้ว");
      return null;
    }
  };


  const handleDelete = async () => {
    try {
      await deleteDetail('Tb_Set_ItmSize', `WHERE Size_Id = ${selectedCar.Size_Id}`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
      await handleClose();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    if (!formData.size_code || !formData.size_name || !formData.size_status) {
      getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วนทุกช่อง");
      return;
    }
    // ตรวจสอบว่าข้อมูลที่แก้ไขว่าซ้ำกับข้อมูลเดิมหรือไม่
    if (
      formData.size_code === initialFormData.size_code &&
      formData.size_name === initialFormData.size_name &&
      formData.size_status === initialFormData.size_status
    ) {
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return;
    }
    try {
      if (mode === 'I') {
        await handleInsertTbSetSize();
      } else if (mode === 'U') {
        await handleUpdateTbSetSize();
      }
      setFormData({
        size_code: "",
        size_name: "",
        size_status: "Y",
        comp_id: localStorage.getItem('company')
      });
      await callInitialize();
      await handleClose();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  };

  return (
    <div
      className={`modal ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="card-title">{mode === 'I' ? `เพิ่ม${name}` : `แก้ไข${name}`}</h4>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="shadow-lg rounded">
                <div className="p-3">
                  <div className="col-12 my-1 mt-2">
                    <div className="d-flex align-items-center">
                      <label className="text-black">Size_Code</label>
                      <input
                        type="text"
                        className="form-control input-spacing"
                        name="size_code"
                        value={formData.size_code || ""}
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-1 mt-2">
                    <div className="d-flex align-items-center">
                      <label className="text-black">Size_Name</label>
                      <input
                        type="text"
                        className="form-control input-spacing"
                        name="size_name"
                        value={formData.size_name || ""}
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex align-items-center">
                      <label className="text-black">สถานะ : </label>
                      <select
                        className="form-select"
                        name="size_status"
                        value={formData.size_status || "Y"} // Set default value to "Y"
                        onChange={handleChange}
                        style={{ color: 'black' }}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <FormAction
                    onSubmit={handleSubmit}
                    onUpdate={handleSubmit}
                    onDelete={handleDelete}
                    mode={mode}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialDataModalTbSetItemSize;