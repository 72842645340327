import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/InitialData/TbSetItemCat/Main';
import InitialDataModalTbSetItemCat from '../../components/Modal/InitialDataModalTbSetItemCat';

// Utils
import {
  getAlert,
  getData,
} from '../../../utils/SamuiUtils';
import { InitialDataTbSetItemCat } from '../../../model/InitialData/InitialDataTbSetItemCat';

function TbSetItemCat() {

  const [mode, setMode] = useState('');
  const [dataMasterList, setDataMasterList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formData, setFormData] = useState(InitialDataTbSetItemCat());


  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = async () => setShowModal(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      setMode('S');
      fetchRealtime();
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const fetchRealtime = async () => {
    try {
      // ดึงข้อมูลตั้งต้นรถขนส่ง Tb_Set_WH และ Tb_Set_Car โดย Join 2 Table
      const masterList = await getData("Tb_Set_ItmCat", "1=1");
      if (masterList && masterList.length > 0) {
        masterList.sort((a, b) => a.Cat_Id - b.Cat_Id);
        setDataMasterList(masterList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const onPageInsert = () => {
    setMode('I');
    handleShow();
  };

  const onRowSelected = async (item) => {
    setMode('U');
    setFormData({
      cat_id: item.Cat_Id,
      cat_code: item.Cat_Code,
      cat_name: item.Cat_Name,
      cat_status: item.Cat_Status,
      comp_id: item.Comp_Id,
    });
    setSelectedCar(item);
    handleShow();
  };

  return (
    <div className="TransportInformation">
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <Main
                masterList={dataMasterList}
                name={'ข้อมูลแบรนด์'}
                onPageInsert={onPageInsert}
                onRowSelected={onRowSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <InitialDataModalTbSetItemCat
        showModal={showModal}
        handleClose={handleClose}
        callInitialize={initialize}
        mode={mode}
        name={'ข้อมูลแบรนด์'}
        formData={formData}
        setFormData={setFormData}
        selectedCar={selectedCar}
      />
    </div>
  );
}

export default TbSetItemCat;