import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';
// React DateTime
import Datetime from 'react-datetime';
import moment from 'moment';
// Components
import Breadcrumbs from '../../Breadcrumbs';
import FormAction from '../../Actions/FormAction';
import { deleteDetail, getAlert } from '../../../../utils/SamuiUtils';

const Form = ({ callInitialize, mode, name, formData, setFormData, selectedData }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);

  useEffect(() => {
    if (mode === 'I') {
      const initialData = {
        emp_id: parseInt(window.localStorage.getItem('emp_id'), 10),
        emp_code: "",
        emp_prefix: "",
        emp_name: "",
        emp_dept: parseFloat(formData.emp_dept, 10),
        emp_position: parseFloat(formData.emp_position, 10),
        emp_position_detail: "",
        emp_startdate: moment().format('YYYY-MM-DD'), // วันที่ปัจจุบัน
        emp_tel: "",
        emp_status: "Y",
        emp_uuser: "",
        emp_ppass: "",
        emp_pin: "",
        gold_so: parseInt(formData.gold_so, 10),
        comp_id: parseInt(window.localStorage.getItem('company'), 10),
      }
      setFormData(initialData);
      setInitialFormData(initialData);
    } else if (mode === 'U') {
      const initialData = {
        emp_id: parseInt(selectedData.Emp_Id, 10),
        emp_code: selectedData.Emp_Code,
        emp_prefix: selectedData.Emp_Prefix,
        emp_name: selectedData.Emp_Name,
        emp_dept: parseInt(selectedData.Emp_Dept, 10),
        emp_position: parseInt(selectedData.Emp_Position, 10),
        emp_position_detail: selectedData.Emp_Position_Detail,
        emp_startdate: selectedData.Emp_StartDate,
        emp_tel: selectedData.Emp_Tel,
        emp_status: selectedData.Emp_Status,
        emp_uuser: selectedData.Emp_UUser,
        emp_ppass: selectedData.Emp_PPass,
        emp_pin: selectedData.Emp_PIN,
        gold_so: parseFloat(selectedData.Gold_SO, 10),
        comp_id: parseInt(window.localStorage.getItem('company'), 10),
      }
      setFormData(initialData);
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      emp_startdate: moment(date).format('DD-MM-YYYY'), // Format date as needed
    });
  };

  const handleInsertEmployee = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-employee`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleUpdateEmployee = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-employee`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDetail('Tb_Set_Employee', `WHERE Emp_Id = ${selectedData.Emp_Id}`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (!formData.emp_code || !formData.emp_prefix || !formData.emp_name || !formData.emp_dept ||
        !formData.emp_position || !formData.emp_position_detail || !formData.emp_startdate || !formData.emp_tel ||
        !formData.emp_status || !formData.emp_uuser || !formData.emp_ppass || !formData.emp_pin || !formData.gold_so ||
        !formData.comp_id) {
        getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วน");
        return;
      }
      if (mode === 'I') {
        await handleInsertEmployee();
      } else if (mode === 'U') {
        await handleUpdateEmployee();
      }
      await callInitialize();
      setFormData({
        emp_id: "", // Int
        emp_code: "",
        emp_prefix: "",
        emp_name: "",
        emp_dept: "",
        emp_position: "",
        emp_position_detail: "",
        emp_startdate: "",
        emp_tel: "",
        emp_status: "Y",
        emp_uuser: "",
        emp_ppass: "",
        emp_pin: "",
        gold_so: "",
        comp_id: parseInt(window.localStorage.getItem('company'), 10), // Ensure comp_id is a number
      })
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  }

  return (
    <form>
      <Breadcrumbs
        isShowStatus={mode === 'U'}
        items={[
          { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
          { name: name, url: '/employee' },
          { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' },
        ]}
      />
      <div className="row mt-1">
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>รหัสพนักงาน</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_code"
              value={formData.emp_code || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Prefix</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_prefix"
              value={formData.emp_prefix || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Name</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_name"
              value={formData.emp_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Dept</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="emp_dept"
              value={formData.emp_dept || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Position</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="emp_position"
              value={formData.emp_position || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Position_detail</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_position_detail"
              value={formData.emp_position_detail || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label>Emp_StartDate</label>
            <Datetime
              className="input-spacing-input-date"
              value={moment(formData.emp_startdate).format('DD-MM-YYYY')}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              onChange={handleDateChange}
              inputProps={{ readOnly: true, disabled: mode === 'U' }}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Tel</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_tel"
              value={formData.emp_tel || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>สถานะ</label>
            <select
              className="form-select"
              name="emp_status"
              value={formData.emp_status || "Y"} // Set default value to "Y"
              onChange={handleChange}
              style={{ color: 'black' }}
            >
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_UUser</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_uuser"
              value={formData.emp_uuser || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_PPass</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_ppass"
              value={formData.emp_ppass || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Emp_Pin</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="emp_pin"
              value={formData.emp_pin || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Gold_So</label>
            <input
              type="number"
              className="form-control input-spacing"
              name="gold_so"
              value={formData.gold_so || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1" />
        <div className="col-3 my-1" />
        <div className="col-3 my-1" />
      </div>
      <div className="row mt-2">
        <FormAction
          onSubmit={handleSubmit}
          onUpdate={handleSubmit}
          onDelete={handleDelete}
          mode={mode}
        />
      </div>
    </form>
  );
};

export default Form;