import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// Components
import Breadcrumbs from '../../Breadcrumbs';
import FormAction from '../../Actions/FormAction';
import { deleteDetail, getAlert } from '../../../../utils/SamuiUtils';

const Form = ({ callInitialize, mode, name, formData, setFormData, selectedData }) => {
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const compId = localStorage.getItem("company");
    if (compId) {
      setFormData((prevData) => ({
        ...prevData,
        comp_id: compId,
      }));
    }
  }, [setFormData]);


  useEffect(() => {
    if (mode === 'I') {
      const initialData = {
        // +1 เพราะเอา Data ก้อนใหม่ไปต่อกับก้อนเดิม
        comp_id: "", // Int
        comp_code: "",
        comp_name_th: "",
        comp_name_en: "",
        comp_shortname: "",
        comp_taxno: "",
        comp_add1: "",
        comp_add2: "",
        comp_add3: "",
        comp_province: "",
        comp_post: "",
        comp_tel1: "",
        comp_tel2: "",
        comp_contact1: "",
        comp_contact2: "",
      }
      // กำหนดค่าเริ่มต้นให้กับ form data
      setFormData(initialData);
      // กำหนดค่าเริ่มต้นให้กับ initial form data
      setInitialFormData(initialData);
    } else if (mode === 'U') {
      const initialData = {
        comp_id: selectedData.Comp_Id, // Int
        comp_code: selectedData.Comp_Code,
        comp_name_th: selectedData.Comp_Name_TH,
        comp_name_en: selectedData.Comp_Name_EN,
        comp_shortname: selectedData.Comp_ShortName,
        comp_taxno: selectedData.Comp_TaxNo,
        comp_add1: selectedData.Comp_Add1,
        comp_add2: selectedData.Comp_Add2,
        comp_add3: selectedData.Comp_Add3,
        comp_province: selectedData.Comp_Province,
        comp_post: selectedData.Comp_Post,
        comp_tel1: selectedData.Comp_Tel1,
        comp_tel2: selectedData.Comp_Tel2,
        comp_contact1: selectedData.Comp_Contact1,
        comp_contact2: selectedData.Comp_Contact2,
      }
      // อัปเดตค่าให้กับ form data
      setFormData(initialData);
      // อัปเดตค่าให้กับ initial form data
      setInitialFormData(initialData);
    }
  }, [mode, setFormData, selectedData]);


  // ฟังก์ชันที่ใช้ในการเปลี่ยนค่าของข้อมูลในฟอร์ม
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInsertCompany = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/insert-company`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "บันทึกสำเร็จ");
      // callInitialize();
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };
  const handleUpdateCompany = async () => {
    try {
      const res = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-company`,
        formData,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        }
      );
      getAlert("OK", "อัปเดตข้อมูลสำเร็จ");
      // callInitialize();
      return res;
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถอัปเดตข้อมูลได้ เนื่องจากมีข้อมูลนี้อยู่ในระบบแล้ว");
      return null;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDetail('Tb_Set_Company', `WHERE Comp_Id = ${selectedData.Comp_Id}`);
      getAlert("OK", "ลบข้อมูลสำเร็จ");
      await callInitialize();
    } catch (error) {
      getAlert("FAILED", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleSubmit = async (e) => {
    try {
      // e.preventDefault();
      // ดักการส่งข้อมูลว่างไปหา API
      if (
        !formData.comp_code ||
        !formData.comp_name_th ||
        !formData.comp_name_en ||
        !formData.comp_shortname ||
        !formData.comp_taxno ||
        !formData.comp_add1 ||
        !formData.comp_add2 ||
        !formData.comp_add3 ||
        !formData.comp_province ||
        !formData.comp_post ||
        !formData.comp_tel1 ||
        !formData.comp_tel2 ||
        !formData.comp_contact1 ||
        !formData.comp_contact2
      ) {
        getAlert("WARNING", "โปรดกรอกข้อมูลให้ครบถ้วน");
        return;
      }
      // ตรวจสอบว่าข้อมูลที่แก้ไขว่าซ้ำกับข้อมูลเดิมหรือไม่
      if (formData.comp_code === initialFormData.comp_code &&
        formData.comp_name_th === initialFormData.comp_name_th &&
        formData.comp_name_en === initialFormData.comp_name_en &&
        formData.comp_shortname === initialFormData.comp_shortname &&
        formData.comp_taxno === initialFormData.comp_taxno &&
        formData.comp_add1 === initialFormData.comp_add1 &&
        formData.comp_add2 === initialFormData.comp_add2 &&
        formData.comp_add3 === initialFormData.comp_add3 &&
        formData.comp_province === initialFormData.comp_province &&
        formData.comp_post === initialFormData.comp_post &&
        formData.comp_tel1 === initialFormData.comp_tel1 &&
        formData.comp_tel2 === initialFormData.comp_tel2 &&
        formData.comp_contact1 === initialFormData.comp_contact1 &&
        formData.comp_contact2 === initialFormData.comp_contact2
      ) {
        getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
        return;
      }
      if (mode === 'I') {
        await handleInsertCompany();
      } else if (mode === 'U') {
        await handleUpdateCompany();
      }
      await callInitialize();
      setFormData({
        comp_id: window.localStorage.getItem('company'), // Int
        comp_code: "",
        comp_name_th: "",
        comp_name_en: "",
        comp_shortname: "",
        comp_taxno: "",
        comp_add1: "",
        comp_add2: "",
        comp_add3: "",
        comp_province: "",
        comp_post: "",
        comp_tel1: "",
        comp_tel2: "",
        comp_contact1: "",
        comp_contact2: "",
      })
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      getAlert("WARNING", "โปรดตรวจสอบข้อมูลอีกครั้ง ข้อมูลนี้มีอยู่ในระบบแล้ว");
      return null;
    }
  }

  return (
    <form>
      <Breadcrumbs
        isShowStatus={mode === 'U'}
        items={[
          { name: 'ข้อมูลตั้งต้น', url: '/initialdata' },
          { name: name, url: '/company' },
          { name: mode === 'U' ? "เรียกดู" + name : "สร้าง" + name, url: '#' },
        ]}
      />
      <div className="row mt-1">
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>บริษัท</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_code"
              value={formData.comp_code || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Name_TH</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_name_th"
              value={formData.comp_name_th || ""}
              onChange={handleChange}

            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Name_EN</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_name_en"
              value={formData.comp_name_en || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_ShortName</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_shortname"
              value={formData.comp_shortname || ""}
              onChange={handleChange}

            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_TaxNo</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_taxno"
              value={formData.comp_taxno || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Add1</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_add1"
              value={formData.comp_add1 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Add2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_add2"
              value={formData.comp_add2 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Add3</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_add3"
              value={formData.comp_add3 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>จังหวัด</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_province"
              value={formData.comp_province || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Post</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_post"
              value={formData.comp_post || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ติดต่อ</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_tel1"
              value={formData.comp_tel1 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>ติดต่อ 2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_tel2"
              value={formData.comp_tel2 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Contact1</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_contact1"
              value={formData.comp_contact1 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-3 my-1">
          <div className="d-flex align-items-center">
            <label className=''>Comp_Contact2</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="comp_contact2"
              value={formData.comp_contact2 || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <FormAction
          onSubmit={handleSubmit}
          onUpdate={handleSubmit}
          onDelete={handleDelete}
          mode={mode}
        />
      </div>
    </form>
  );
};

export default Form;