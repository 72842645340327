import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../../../utils/SamuiUtils';
import { formatThaiDateUi } from '../../../utils/DateUtils';

const SoModal = ({ showSoModal, handleSoClose, soDataList, dlLineList, onConfirmSoSelection, onRowSelectDL }) => {
    const [searchTerm, setSearchTerm] = useState('');
    // const [filteredSoDataList, setFilteredSoDataList] = useState([]);
    const [filteredDlLineList, setFilteredDlLineList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedType, setSelectedType] = useState('DL'); // SO = ใบขาย, DL = สายส่ง

    useEffect(() => {
        // if (selectedType === 'SO') {
        //     setFilteredSoDataList(
        //         soDataList.filter(so =>
        //             so.Doc_No.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //             so.AR_Name.toLowerCase().includes(searchTerm.toLowerCase())
        //         )
        //     );
        // } else {
        //     setFilteredDlLineList(
        //         dlLineList.filter(dl =>
        //             dl.DL_LineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //             dl.DL_Remark.toLowerCase().includes(searchTerm.toLowerCase())
        //         )
        //     );
        // }
        setFilteredDlLineList(
            dlLineList.filter(dl =>
                dl.DL_LineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                dl.DL_Remark.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, selectedType, soDataList, dlLineList]);

    useEffect(() => {
        if (showSoModal) {
            setSearchTerm('');
            setSelectedItems([]);
            // checkCompanyAndSetType();
        }
    }, [showSoModal]);

    // ถ้าน้ำ (CompId = 1)ให้ Default ที่ใบขาย || ถ้าน้ำแข็ง (CompId = 2) ให้ Default ที่สายส่ง
    // const checkCompanyAndSetType = () => {
    //     const company = Number(window.localStorage.getItem('company'));
    //     if (company === 1) {
    //         setSelectedType('SO');
    //     } else if (company === 2) {
    //         setSelectedType('DL');
    //     }
    // };

    const handleCheckboxChange = (so) => {
        const updatedSelectedItems = selectedItems.some(item => item.Doc_No === so.Doc_No)
            ? selectedItems.filter(item => item.Doc_No !== so.Doc_No)
            : [...selectedItems, so];

        setSelectedItems(updatedSelectedItems);
    };

    const handleConfirmSelection = () => {
        onConfirmSoSelection(selectedItems);
    };

    const clearSelection = () => {
        setSelectedItems([]);
    };

    return (
        <>
            <div className={`modal ${showSoModal ? 'show' : ''}`} style={{ display: showSoModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">รายชื่อใบขาย และ สายส่ง</h5>
                            <button type="button" className="close" onClick={handleSoClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span className="fw-bold">ค้นหาเอกสาร</span>
                                        <input
                                            style={{ width: '100%' }}
                                            type="text"
                                            className="form-control"
                                            placeholder={`ค้นหา${selectedType === 'SO' ? 'ใบขาย (SO)' : 'สายส่ง'}`}
                                            value={searchTerm || ''}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                    <div className="form-group">
                                        <span className="fw-bold">ประเภทการเลือกข้อมูล</span>
                                        <div className="radio-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="selectedType"
                                                value="SO"
                                                id="typeSO"
                                                checked={selectedType === 'SO'}
                                                onChange={() => setSelectedType('SO')}
                                            />
                                            <label className="form-check-label ml-2" htmlFor="typeSO">ใบขาย</label>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="selectedType"
                                                value="DL"
                                                id="typeDL"
                                                checked={selectedType === 'DL'}
                                                onChange={() => setSelectedType('DL')}
                                            />
                                            <label className="form-check-label ml-2" htmlFor="typeDL">สายส่ง</label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            {/* ดึงจากใบขาย */}
                            {/* {selectedType === 'SO' && (
                                <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th className="text-center" style={{ width: '3%' }}>
                                                    <input type="checkbox" onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        setSelectedItems(checked ? filteredSoDataList : []);
                                                    }} checked={filteredSoDataList.length > 0 && selectedItems.length === filteredSoDataList.length} />
                                                </th>
                                                <th className="text-center" style={{ width: '15%' }}>เลขที่เอกสาร (SO)</th>
                                                <th className="text-center" style={{ width: '30%' }}>AR_NAME</th>
                                                <th className="text-center" style={{ width: '25%' }}>รายละเอียดเอกสาร</th>
                                                <th className="text-center" style={{ width: '10%' }}>วันที่รับเข้า</th>
                                                <th className="text-center" style={{ width: '5%' }}>ราคารวม</th>
                                                <th className="text-center" style={{ width: '12%' }}>สถานะจ่าย</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredSoDataList.length > 0 ? (
                                                filteredSoDataList.map((so, index) => (
                                                    <tr
                                                        key={so.Doc_ID || index + 1}
                                                        onClick={() => handleCheckboxChange(so)}
                                                        style={{ cursor: 'pointer' }}>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(item => item.Doc_No === so.Doc_No)}
                                                                onChange={() => handleCheckboxChange(so)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                        <td className="text-center">{so.Doc_No}</td>
                                                        <td className="text-left">{so.AR_Name}</td>
                                                        <td className="text-left">{so.Doc_Remark1}</td>
                                                        <td className="text-center">{formatThaiDateUi(so.Doc_Date)}</td>
                                                        <td className="text-end">{formatCurrency(so.NetTotal)}</td>
                                                        <td className="text-center">{so.DocStatusPaid_Name}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7">
                                                        <center><h5>ไม่พบข้อมูล</h5></center>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )} */}

                            {/* ดึงจากสายส่ง */}
                            {selectedType === 'DL' && (
                                <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th className="text-center" style={{ width: '10%' }}>ลำดับ</th>
                                                <th className="text-center" style={{ width: '20%' }}>ชื่อสายส่ง</th>
                                                <th className="text-center" style={{ width: '70%' }}>รายละเอียดสายส่ง</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredDlLineList.length > 0 ? (
                                                filteredDlLineList.map((dl, index) => (
                                                    <tr
                                                        key={dl.DL_Id || index + 1}
                                                        onClick={() => onRowSelectDL(dl)}
                                                        style={{ cursor: 'pointer' }}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-left">{dl.DL_LineName}</td>
                                                        <td className="text-left">{dl.DL_Remark}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3">
                                                        <center><h5>ไม่พบข้อมูล</h5></center>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" onClick={handleConfirmSelection}>ยืนยันการเลือก</button>
                            <button className="btn btn-danger" onClick={clearSelection}>ยกเลิกการเลือก</button>
                            <button className="btn btn-secondary" onClick={handleSoClose}>ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SoModal;